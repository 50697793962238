import { message } from "antd";
import { action, autorun, computed, observable, toJS, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

const uniqBy = require("lodash.uniqby");
const removeBy = require("lodash.remove");


export class LiveStore {
    constructor() {
        // List targets by investigation
        this.liveTargetsByInvestigation = [];
        // Show live targets
        this.liveTargets = [];
        // Currently selected targets
        this.selectedTarget = null;
        // Markers bound to the UI
        this.boundMarkers = [];
        // Markers to be hidden
        this.hiddenMarkers = [];
        // Boolean for data
        this.hasNoData = false;
        // Currently viewing target info box
        this.sidebarSelectedTargets = [];
        this.sidebarSelectedTargetsPRECONF = [];
        // Currently open sidebar tabs
        this.sidebarOpenTabs = [];
        // array for devices with successful cloned captures on them
        this.clonedmarkers = [];
        //list of clone captures for live view
        this.cloneCaptures = [];
        this.targetsClickedForPoints = [];
        this.pointsForRespectiveTargets = [];

        this.showMap = false;
        this.api = API;
        this.cloneCaptureMapPoint = null;
        makeAutoObservable(this);
    }

    // getLiveTargets(){
    //   return this.liveTargets
    // }

    // TODO: Refactor this to use the new socket handler
    setSocketData(data) {
        // this will have record_type
        const target = data;

        // Replace in maps
        // check for epouchs
        const targetIndex = this.liveTargets.findIndex((t) => {
            return t.device_id === target.device_id;
        })
        console.log("targetIndex", data);
        if (typeof(data.latitude) === 'string') {
            data.latitude = parseFloat(data.latitude);
        }
        if (typeof(data.longitude) === 'string') {
            data.longitude = parseFloat(data.longitude);
        }
        if ( (targetIndex === -1) || (data.position_lastCommsTimestamp > this.liveTargets[targetIndex].position_lastCommsTimestamp)) {
            this.liveTargets = this.liveTargets.filter((existingTarget) => {
                return target.device_id !== existingTarget.device_id;
            });
            console.log("live targetIndex", targetIndex);
            this.liveTargets.push(target);
        }

        // Replace in Sidebar
        // Look for the investigation in the array
        const indexOfInvestigationInSideBar = this.liveTargetsByInvestigation.findIndex(
            (investigation) => investigation.id === target.investigation_id
        );

        if (indexOfInvestigationInSideBar > -1) {
            if (
                toJS(this.liveTargetsByInvestigation)[indexOfInvestigationInSideBar]
            ) {
                if (target.class === "RED") {
                    const currentInvestigation = toJS(this.liveTargetsByInvestigation)[
                        indexOfInvestigationInSideBar
                    ];
                    if (target.record_type === 'CELLTOWER') {
                        target.lastCellPoint = {
                            CELL_ACCURACY: target.gps_accuracy,
                            CELL_LAT: target.latitude,
                            CELL_LNG: target.longitude,
                            CELL_TIME: target.position_lastCommsTimestamp,
                        }
                    }

                    // Update bounds
                    if (this.isTargetCurrentlyBounded(target)) {
                        removeBy(this.boundMarkers, {
                            device_id: target.device_id,
                        });
                        this.boundMarkers.push(target);
                        this.boundMarkers = uniqBy(toJS(this.boundMarkers), "device_id");
                    }

                    removeBy(currentInvestigation.target, {
                        device_id: target.device_id,
                    });
                    // Update live panel
                    currentInvestigation.target = uniqBy(
                        [...currentInvestigation.target, target],
                        "device_id"
                    );
                    this.liveTargetsByInvestigation[
                        indexOfInvestigationInSideBar
                    ] = currentInvestigation;
                } else {
                    const currentInvestigation = toJS(this.liveTargetsByInvestigation)[
                        indexOfInvestigationInSideBar
                    ];

                    // Update bounds
                    if (this.isTargetCurrentlyBounded(target)) {
                        removeBy(this.boundMarkers, {
                            device_id: target.device_id,
                        });
                        this.boundMarkers.push(target);
                        this.boundMarkers = uniqBy(toJS(this.boundMarkers), "device_id");
                    }

                    removeBy(currentInvestigation.investigator, {
                        device_id: target.device_id,
                    });

                    currentInvestigation.investigator = uniqBy(
                        [...currentInvestigation.investigator, target],
                        "device_id"
                    );
                    this.liveTargetsByInvestigation[
                        indexOfInvestigationInSideBar
                    ] = currentInvestigation;
                }
            }
        }
        console.log(target)
    }

    dispose = autorun(() => {
        const liveTargets = this.liveTargets;
        if (liveTargets) {
            liveTargets.forEach((target) => {
                // If the marker has coordinates and also there is no previously selected marker
                if (
                    target.latitude !== null &&
                    target.longitude !== null &&
                    this.selectedTarget &&
                    !Object.keys(this.selectedTarget).length
                ) {
                    this.setSelectedTarget(target);
                } else {
                }
            });
        }
    });

    get filteredLiveResults() {
        const markersToShow = [];
        this.liveTargets.forEach((target) => {

            if (this.isTargetCurrentlyHidden(target)) {
            } else {
                markersToShow.push(target);
            }
        });

        return this.liveTargets;
    }
    //wifi cloned filter
    get WIFIfilteredLiveResults() {
        const markersToShow = [];
        this.liveTargets.forEach((target) => {
            if (this.isTargetCurrentlyCloned(target)) {
            } else {
                markersToShow.push(target);
            }
        });
        return markersToShow;
    }

    async getliveWifiTargets() {
        const response = await this.api.live.getLiveTargets();
        return response;
    }

    async getLiveTargets(spin = false) {
        if (!spin) {
            this.showMap = false;
        }
        const response = await this.api.live.getLiveTargets();

        const filteredInvestigations = response.map((investigation) => {
            const investigators = investigation.investigator.filter((marker) => {
                const currentDateinSeconds = Date.now() / 1000;
                const currentMarkerPositionTime = Number(
                    marker.position_lastCommsTimestamp
                );

                const TIME_EXPIRY_THRESHOLD = 3600 * 2;

                return (
                    currentDateinSeconds - currentMarkerPositionTime <
                    TIME_EXPIRY_THRESHOLD
                );
            });

            investigation.investigator = investigators;
            return investigation;
        });

        this.liveTargetsByInvestigation = filteredInvestigations;
        const targets = [];
        if (this.liveTargetsByInvestigation.length > 0) {
            this.liveTargetsByInvestigation.forEach((investigation) => {
                // Handle when the response doesn't send the investigators field.
                const investigations =
                    typeof investigation.investigator !== "undefined"
                        ? investigation.investigator
                        : [];

                const filteredInvestigators = investigations.filter((marker) => {
                    const currentDateinSeconds = Date.now() / 1000;
                    const currentMarkerPositionTime = Number(
                        marker.position_lastCommsTimestamp
                    );
                    // 10 minutes expiry
                    const TIME_EXPIRY_THRESHOLD = 600;

                    return (
                        currentDateinSeconds - currentMarkerPositionTime <
                        TIME_EXPIRY_THRESHOLD
                    );
                });

                targets.push(...investigation.target, ...filteredInvestigators);
            });

            this.hasNoData = false;
        } else {
            this.hasNoData = true;
        }

        this.liveTargets = targets;
        this.showMap = true;
        this.getClonesForDevices();
    }

    getClonesForDevices() {
        this.liveTargets.forEach((target) => {
            if (target.device_type_name === 'Huntsman') {
                this.api.target.getLiveCloneCaptures(target.device_id).then((response) => {
                    target.cloneCapture = response;
                });
            }
        });
    }

    setSelectedTarget(target) {
        if (target.latitude !== null && target.longitude !== null) {
            this.selectedTarget = target;
        } else {
            message.warn("Target does not have coordinates.");
        }

        // message.info(`Selected target: ${target.name}`);
    }

    isTargetCurrentlyBounded(target) {
        const isBound =
      this.boundMarkers.filter((t) => {
          return t.device_id === target.device_id;
      }).length > 0;

        return isBound;
    }

    setBoundMarkers(marker) {
        const currentMarkerInBounds = this.boundMarkers.some((boundMarker) => {
            // eslint-disable-next-line
      return boundMarker.device_id === marker.device_id;
        });

        if (currentMarkerInBounds) {
            this.boundMarkers = this.boundMarkers.filter(function (boundMarker) {
                // eslint-disable-next-line
        return boundMarker.device_id !== marker.device_id;
            });

            // message.info("Target tracking disabled.");
        } else {
            this.boundMarkers.push(marker);
            // message.info("Tracking target ..");
        }
    }

    async pointsUpdate() {
        this.pointsForRespectiveTargets.forEach((target) => {
            this.api.analysis.getLastFewPoints({ device_id: target.device_id, limit: 10 }).then((response) => {
                target.points = response;
            });
        });
    }

    async setPointsForATarget(target) {
        if (target) {
            const targetAlreadyClicked = this.targetsClickedForPoints.filter(x => x === target.target_id);
            if (targetAlreadyClicked && targetAlreadyClicked.length > 0) {
                this.targetsClickedForPoints = this.targetsClickedForPoints.filter(function (boundMarker) {
                    // eslint-disable-next-line
          return boundMarker !== targetAlreadyClicked[0];
                });

                this.pointsForRespectiveTargets = this.pointsForRespectiveTargets.filter(function (boundMarker) {
                    // eslint-disable-next-line
          return boundMarker.target_id !== targetAlreadyClicked[0];
                });

            } else {
                this.targetsClickedForPoints.push(target.target_id);

                const response = await this.api.analysis.getLastFewPoints({ device_id: target.device_id, limit: 10 })
                    .then((points) => {
                        return points;
                    });

                const obj = {
                    target_id: target.target_id,
                    investigation_id: target.investigation_id,
                    latitude: target.latitude,
                    longitude: target.longitude,
                    class: target.class,
                    name: target.name,
                    device_type_name: target.device_type_name,
                    gps_accuracy: target.gps_accuracy,
                    device_id: target.device_id,
                    points: response
                }
                this.pointsForRespectiveTargets.push(obj);
            }

        }

    }

    setSidebarSelected(marker) {
        const currentMarkerSelected = this.sidebarSelectedTargets.some(
            (sidebar) => {
                // eslint-disable-next-line
        return sidebar.device_id == marker.device_id;
            }
        );

        if (currentMarkerSelected) {
            this.sidebarSelectedTargets = this.sidebarSelectedTargets.filter(
                function (sidebar) {
                    return sidebar.device_id !== marker.device_id;
                }
            );

            // message.info("Target tracking disabled.");
        } else {
            this.sidebarSelectedTargets.push(marker);

            // message.info("Tracking target ..");
        }
    }

    setSidebarSelectedPRECONF(marker) {
        const currentMarkerSelected = this.sidebarSelectedTargetsPRECONF.some(
            (sidebar) => {
                // eslint-disable-next-line
        return sidebar.device_id == marker.device_id;
            }
        );

        if (currentMarkerSelected) {
            this.sidebarSelectedTargetsPRECONF = this.sidebarSelectedTargetsPRECONF.filter(
                function (sidebar) {
                    return sidebar.device_id !== marker.device_id;
                }
            );

        } else {
            this.sidebarSelectedTargetsPRECONF.push(marker);
        }
    }

    isTargetCurrentlyHidden(target) {
        return (
            this.hiddenMarkers.filter((t) => t.device_id === target.device_id)
                .length > 0
        );
    }

    isTargetCurrentlyCloned(target) {
        return (
            this.hiddenMarkers.filter((t) => t.device_id === target.device_id)
                .length > 0
        );
    }

    clonedvisability(marker) {

        const currentClonedmarker = this.clonedmarkers.some((currentcloned) => {
            // eslint-disable-next-line
      return currentcloned.device_id == marker.device_id;
        });

        if (currentClonedmarker) {
            this.clonedmarkers = this.clonedmarkers.filter(function (currentcloned) {
                // eslint-disable-next-line
        return currentcloned.device_id != marker.device_id;
            });

            // message.info(`Showing target: ${marker.name}`);
        } else {
            this.clonedmarkers.push(marker);
            // message.info(`Hiding target: ${marker.name}`);
        }
    }

    // force: true will show target and false will hide target
    toggleVisibility(marker, force = null) {
        let currentMarkerHidden = this.hiddenMarkers.some((hiddenMarker) => {
            // eslint-disable-next-line
            return hiddenMarker.device_id == marker.device_id;
        });
        if (force !== null) {
            currentMarkerHidden = force;
        }
        if (currentMarkerHidden) {
            this.hiddenMarkers = this.hiddenMarkers.filter(function (hiddenMarker) {
                // eslint-disable-next-line
                return hiddenMarker.device_id != marker.device_id;
            });

            // message.info(`Showing target: ${marker.name}`);
        } else {
            this.hiddenMarkers.push(marker);

            // message.info(`Hiding target: ${marker.name}`);
        }
    }

    cloneCaptureLive(obj) {
        const data = obj;
        const currentInvestigation = toJS(this.liveTargetsByInvestigation);

        const investigationCLoned = currentInvestigation.findIndex((element) => {
            return element.id === data.investigation_id;
        })
        const targetForCloned = currentInvestigation[investigationCLoned].target.findIndex((element) => {
            return element.device_id === data.device_id
        })

        if (currentInvestigation[investigationCLoned].target[targetForCloned].cloneCapture) {
            // check index to see if in the thing
            let index = currentInvestigation[investigationCLoned].target[targetForCloned].cloneCapture.findIndex(element => element.id === data.id);
            if (index >= 0) {
                currentInvestigation[investigationCLoned].target[targetForCloned].cloneCapture[index] = data;
            } else {
                currentInvestigation[investigationCLoned].target[targetForCloned].cloneCapture.push(data);
            }
        } else {
            currentInvestigation[investigationCLoned].target[targetForCloned].cloneCapture = [data];
        }
        this.liveTargetsByInvestigation[investigationCLoned] = currentInvestigation[investigationCLoned];

        const targetList = this.liveTargets;
        let index = targetList.findIndex((element) => {
            return element.device_id === data.device_id;
        })
        this.liveTargets[index].cloneCapture = [data];

    }
}

export default createContext(new LiveStore());
