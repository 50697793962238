import {
    GoogleMap,
    Polygon,
    TrafficLayer,
    Marker,
    OverlayView,
    InfoWindow,
    Circle,
    InfoBox,
    Rectangle,
    DrawingManager,
    Polyline,
} from "@react-google-maps/api";
import React, { useEffect, useState, useRef, useContext } from "react";
import getPublicURL from "../../utilities/public-url.utility";
import { Badge, Button, message, Spin } from "antd";
import { getGMTDateTime, getTimeZones, mapDefault, convertSpeed } from "../../util/CommonUtils";
import { getBatteryInfo, macToMAC } from "../../util/CommonUtils";
import StatusDot from "../StatusDot";
import { Table, Form, Input, Select, Radio } from "antd";
import { primaryButton, secondaryButton } from "../../styles";
import API from "../../services/api";
import "./index.css";
import { BatteryFull, GeoAltFill, CarFrontFill, HouseFill, ClockFill, HddRackFill } from "react-bootstrap-icons";
import { observer } from "mobx-react-lite";
import socketHandlerStore from "../../stores/socketHandler.store";
import AddressDisplay from "../AnalysisTripsPanel/AddressDisplay";


const LiveMapBEN = (({
    defaultCenter,
    style,
    targetsClickedForPoints,
    currentQueryLocation,
    markersVisibleOnMap,
    hiddenMarkers,
    speedUnitString,
    livePinsVisibleOnMap,
    hiddenPins,
    pinTrigger,
    setPinTrigger,
    placedMarker,
    addLivePin,
    newName,
    lock,
    geofenceActive,
    geofenceDetails,
    users,
    setGeofenceActive,
    lockedTargets,
    newLock,
    setNewLock,
    geofences,
    type,
    setActiveShape,
    activeShape,
    rectangleBounds,
    setRectangleBounds,
    center,
    setCenter,
    radius,
    setRadius,
    cellTower,
    cloneCapturePoint,
}) => {
    function convertToTime(timestamp) {
        let date = new Date(0);
        date.setUTCSeconds(timestamp);
        let hours = date.getHours();
        let minute = date.getMinutes();
        return (`${hours}:${minute}`);
    }
    const socketHandle = useContext(socketHandlerStore);

    const columns = [
        { title: "Entity", dataIndex: "alias", key: "alias" },
        { title: "Device", dataIndex: "wifi_device_name", key: "wifi_device_name" },
        { title: "MAC", dataIndex: "mac_address", key: "mac_address", render: item => macToMAC(item) },
        { title: "Time Seen", dataIndex: "timestamp", key: "timestamp", render: item => convertToTime(item) },

    ]

    const [map, setMap] = useState();
    const [newMarker, setNewMarker] = useState();
    const [newPin, setNewPin] = useState({ lat: 0, lng: 0 });

    const [hover, setHover] = useState(-1);
    const [plotHover, setPlotHover] = useState([]);
    const [plots, setPlots] = useState([]);
    const [fence, setFence] = useState([]);
    const [showAccuracy, setShowAccuracy] = useState([]);

    const [drawingManager, setDrawingManager] = useState();

    useEffect(() => {
        if (map && center && radius && rectangleBounds) {
            if (activeShape === "rectangle") {
                map.fitBounds(rectangleBounds);
            } else {
                if (radius !== 0) {
                    try {
                        map.fitBounds({ north: center.lat() + (radius / 111000), south: center.lat() - (radius / 111000), east: center.lng() + (radius / 111000), west: center.lng() - (radius / 111000) });
                    } catch (error) {
                        map.fitBounds({ north: center.lat + (radius / 111000), south: center.lat - (radius / 111000), east: center.lng + (radius / 111000), west: center.lng - (radius / 111000) });
                    }
                }
            }
        }
    }, [radius, center, rectangleBounds]);

    useEffect(() => {
        if (map) {
            if (currentQueryLocation) {
                if (currentQueryLocation.lat && currentQueryLocation.lng) {
                    const location = { lat: currentQueryLocation.lat, lng: currentQueryLocation.lng };
                    map.panTo(location);
                }

                fenceDraw()
            }
        }
    }, [currentQueryLocation])

    useEffect(() => {
        if(map && socketHandle.livePanToTarget) {
            // find the target
            // set pan to back to null
            const panRef = markersVisibleOnMap.find((marker) => marker.target_id === socketHandle?.livePanToTarget?.targetId);
            if (panRef) {
                const location = { lat: panRef.latitude, lng: panRef.longitude };
                map.panTo(location);
            }
            socketHandle.livePanToTarget = null;
        }
    }, [socketHandle.livePanToTarget]);

    useEffect(() => {
        setPlots([])
        if (targetsClickedForPoints) {
            targetsClickedForPoints.map((target) => {
                const length = target.points.length
                for (let i = 0; i < length; i++) {
                    setPlots(plots => [...plots, target.points[i]])
                }
            })
        }
    }, [targetsClickedForPoints])

    useEffect(() => {
        if (!map) return
        if (lockedTargets.length > 0) {
            const points = lockedTargets.map((point) => ({
                ...point,
                lng: point.longitude,
                lat: point.latitude,
            }));


            const bounds = new window.google.maps.LatLngBounds();
            points.forEach((point) => {
                bounds.extend(point);
            });
            map.setOptions({
                maxZoom: map.getZoom()
            })
            map.fitBounds(bounds);
            map.setOptions({
                maxZoom: null
            })
        }
    }, [lockedTargets])

    useEffect(() => {
        if (drawingManager) {
            drawingManager.setDrawingMode(null);
            switch (type) {
                case "circle":
                    drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.CIRCLE);
                    break;
                case "cancel":
                    drawingManager.setDrawingMode(null);
                    setActiveShape(null);
                    break;
                case "rectangle":
                    drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.RECTANGLE);
                    break;
                default:
                    break;
            }
        }
    }, [type]);

    useEffect(() => {
        if (drawingManager) {
            if (activeShape) {
                drawingManager.setDrawingMode(null);
            }
        }
    }, [activeShape]);

    const fenceDraw = (() => {
        if (map) {
            const zoom = 22 - map.getZoom()
            const offset = 0.0001
            const offset2 = 0.00005
            if (map) {
                const center = map.getCenter()
                if (!center) return;
                setFence([
                    {
                        lat: center.lat() + offset2 + offset * (1.01 * zoom * zoom) * 0.25 * zoom,
                        lng: center.lng() + offset2 + offset * (1.01 * zoom * zoom) * 0.25 * zoom
                    },
                    {
                        lat: center.lat() + offset2 + offset * (1.01 * zoom * zoom) * 0.25 * zoom,
                        lng: center.lng() - offset2 - offset * (1.01 * zoom * zoom) * 0.25 * zoom
                    },
                    {
                        lat: center.lat() - offset2 - offset * (1.01 * zoom * zoom) * 0.25 * zoom,
                        lng: center.lng() - offset2 - offset * (1.01 * zoom * zoom) * 0.25 * zoom
                    },
                    {
                        lat: center.lat() - offset2 - offset * (1.01 * zoom * zoom) * 0.25 * zoom,
                        lng: center.lng() + offset2 + offset * (1.01 * zoom * zoom) * 0.25 * zoom
                    }
                ])
            }
        }
    })

    const QuickGeofence = ((e) => {
        setGeofenceActive(false)
        console.log(e);
        console.log(geofenceDetails);
        API.geofence.createGeofence({
            name: e.geofenceName,
            target_id: [geofenceDetails.target_id],
            fence: {
                type: "Polygon",
                coordinates: [[
                    [
                        fence[0].lng,
                        fence[0].lat
                    ],
                    [
                        fence[1].lng,
                        fence[1].lat
                    ],
                    [
                        fence[2].lng,
                        fence[2].lat
                    ],
                    [
                        fence[3].lng,
                        fence[3].lat
                    ]
                ]]
            },
            timezone: e.timezone,
            apply_geofence_datetime: 0,
            expired_at: Date.now() / 1000 + e.duration,
        }).then(res => {
            if (res.error) {
                message.error("There was an issue with your Geofence")
            } else {
                message.success("Geofence created successfully")
            }
        })

        // quick geo fence is both in and out
        // set up notifications for users
        API.notificationStreams.updateStreams({
            type: `(In) ${e.geofenceName}`,
            target_id: [geofenceDetails.target_id],
            investigation_id: geofenceDetails.investigation_id,
            user_ids: e.users
        }).then((response) => {
            console.log(response);
        });

        API.notificationStreams.updateStreams({
            type: `(Out) ${e.geofenceName}`,
            target_id: [geofenceDetails.target_id],
            investigation_id: geofenceDetails.investigation_id,
            user_ids: e.users
        }).then((response) => {
            console.log(response);
        });
    })

    const rectangleDraw = (e) => {
        e.setMap(null);
        setActiveShape(type);
        setRectangleBounds({
            north: e.bounds.getNorthEast().lat(),
            south: e.bounds.getSouthWest().lat(),
            east: e.bounds.getNorthEast().lng(),
            west: e.bounds.getSouthWest().lng(),
        });
    }

    useEffect(() => {
        console.log('clicked');
    }, [setShowAccuracy]);

    const circleDraw = (e) => {
        e.setMap(null);
        setActiveShape(type);
        setCenter(e.center)
        setRadius(e.radius)
    }

    const popup = (target) => {
        setHover(target);
    }

    const popdown = (target) => {

        setHover(-1);
    }

    useEffect(() => {
        if (map) {
            setNewPin({
                lat: map.getCenter().lat(),
                lng: map.getCenter().lng(),
            });
        }
    }, [pinTrigger])

    const submitHandle = (e) => {

        if (newMarker) {
            addLivePin(e.name, e.type, newMarker.getPosition().lat(), newMarker.getPosition().lng());
        }

        setPinTrigger(false);
    }

    function visabiltyCheck(accessPoint) {
        if (accessPoint && hiddenMarkers) {
            let index = hiddenMarkers.findIndex(point => point.target_id === accessPoint.target_id);
            if (index >= 0) {
                return false;
            }
        }
        return true;
    }

    function visabiltyCheckPin(accessPoint) {
        if (accessPoint && hiddenPins) {
            let index = hiddenPins.findIndex(point => point.id === accessPoint.id);
            if (index >= 0) {
                return false;
            }
        }
        return true;
    }

    const [located, setLocated] = useState(false);

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const success = (pos) => {
        const crd = pos.coords;


        if (located === false && map) {
            map.panTo({
                lat: crd.latitude,
                lng: crd.longitude,
            });
            setLocated(true);
        }
    }

    const fail = (error) => {

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    navigator.geolocation.getCurrentPosition(success, fail, options);

    return (
        <div style={style}>
            {window.google ?
                <GoogleMap
                    zoom={15}
                    onIdle={() => {
                        if (!map) return
                        if (lockedTargets.length > 0) {
                            const points = lockedTargets.map((point) => ({
                                ...point,
                                lng: point.longitude,
                                lat: point.latitude,
                            }));

                            // const bounds = new window.google.maps.LatLngBounds();
                            // points.forEach((point) => {
                            //     bounds.extend(point);
                            // });
                            // map.setOptions({
                            //     maxZoom: map.getZoom()
                            // })
                            // map.fitBounds(bounds);
                            // map.setOptions({
                            //     maxZoom: null
                            // })
                        }
                        fenceDraw()
                        if (map.getCenter()) {
                            localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                        }
                    }}
                    onLoad={(map) => { setMap(map) }}
                    mapContainerStyle={{
                        height: "100vh",
                        width: "100%"
                    }}
                >
                    <div style={{ display: 'none' }}>
                        <DrawingManager
                            onLoad={(drawingManager) => { setDrawingManager(drawingManager) }}
                            onRectangleComplete={rectangleDraw}
                            onCircleComplete={circleDraw}
                            options={{
                                drawingControl: false,
                            }}
                            style={{ display: "none" }}
                        />
                    </div>
                    {pinTrigger &&
                        <Marker
                            onLoad={(mark) => { setNewMarker(mark) }}
                            position={newPin}
                            draggable={true}
                            icon={{
                                throwIfNamespace: false,
                                path: "M 0 0 L 0 -30 A 10 10 0 1 0 -3 -30 L -3 0 L 0 0Z",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                fillColor: placedMarker,
                                strokeColor: 'black',
                                // fillColor: pin.colour,
                                scale: 1,
                                // rotation: 180,
                                anchor: new window.google.maps.Point(-1.5, 0),
                            }}
                        >
                            <InfoBox
                                options={{
                                    closeBoxURL: ``,
                                    enableEventPropagation: true,
                                    pixelOffset: new window.google.maps.Size(-175, -285),
                                    disableAutoPan: true
                                }}
                                position={newPin}
                            >
                                <div
                                    style={{
                                        backgroundColor: "white" || "black",
                                        padding: "5px",
                                        color: "black",
                                        borderRadius: "5px",
                                        fontSize: "12px",
                                        width: "350px",
                                    }}
                                >
                                    <Form
                                        onFinish={submitHandle}
                                    >
                                        <h5>Name the pin for {newName}</h5>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                        >
                                            <Input
                                                placeholder="Name"
                                            ></Input>
                                        </Form.Item>
                                        <Form.Item
                                            name="type"
                                            label="Type"
                                        >
                                            <Select>
                                                <Select.Option value="persistent">Persistent</Select.Option>
                                                <Select.Option value="temporary">Temporary</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Button
                                            style={primaryButton()}
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            style={secondaryButton()}
                                            onClick={() => setPinTrigger(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Form>
                                </div>
                            </InfoBox>

                        </Marker>
                    }
                    {markersVisibleOnMap && markersVisibleOnMap
                        .map((target, index) => {
                            if (target.latitude && target.longitude) {
                                return (
                                    <React.Fragment>
                                        {
                                            (target.device_type === "wifi/gps" || target.device_type === "wifi") ?
                                                (<div style={{ zIndex: "1" }}>
                                                    <Circle
                                                        visible={visabiltyCheck(target)}
                                                        fillColor="#03f8fc"
                                                        fillOpacity={0.5}
                                                        center={{ lat: Number(target.latitude) || 0.0, lng: Number(target.longitude) || 0.0 }}
                                                        radius={target.gps_accuracy}
                                                    />
                                                </div>)
                                                : []
                                        }
                                        {
                                            <div >
                                                <Marker
                                                    visible={target.speed > 2 && visabiltyCheck(target)}
                                                    position={{ lat: Number(target.latitude) || 0.0, lng: Number(target.longitude) || 0.0 }}
                                                    icon={{
                                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                        fillColor: target.colour || "black",  //needed for mictrack
                                                        fillOpacity: 1,
                                                        strokeWeight: 5,
                                                        strokeColor: target.colour || "black",  //needed for mictrack
                                                        rotation: target.heading || 0, //needed for mictrack
                                                        scale: 5,
                                                        anchor: new window.google.maps.Point(0, 5),
                                                        // zIndex: 2,
                                                        optimized: false,
                                                    }}
                                                    zIndex={2}
                                                    onMouseEnter={() => popup(index)}
                                                    onMouseOut={() => popdown(index)}
                                                    onMouseOver={() => popup(index)}
                                                    onClick={() => {
                                                        console.log("clicked")
                                                        let newShowAccuracy = [...showAccuracy];
                                                        newShowAccuracy[index] = !newShowAccuracy[index];
                                                        setShowAccuracy(newShowAccuracy);
                                                    }}
                                                />
                                            </div>
                                        }
                                        <Marker
                                            visible={visabiltyCheck(target)}
                                            zIndex={100}
                                            position={{ lat: Number(target.latitude) || 0.0, lng: Number(target.longitude) || 0.0 }}
                                            icon={{
                                                path: "M -1 0 A 1 1 0 0 0 1 0 A 1 1 0 0 0 -1 0",
                                                fillColor: (target.position_lastCommsTimestamp > Date.now() / 1000 - 30) ? target.colour : "black",
                                                fillOpacity: 1,
                                                strokeWeight: 5,
                                                strokeColor: target.colour || "black",  //needed for mictrack
                                                rotation: 0, //needed for mictrack
                                                scale: 10,
                                                anchor: new window.google.maps.Point(0, 0),
                                                zIndex: 99,
                                                optimized: false,
                                            }}
                                            onMouseEnter={() => popup(index)}
                                            onMouseOut={() => popdown(index)}
                                            onMouseOver={() => popup(index)}
                                            onClick={() => {
                                                console.log("clicked")
                                                let newShowAccuracy = [...showAccuracy];
                                                newShowAccuracy[index] = !newShowAccuracy[index];
                                                setShowAccuracy(newShowAccuracy);
                                            }}
                                        >
                                            {/* {markerContents(target)} */}
                                            <InfoBox
                                                visible={visabiltyCheck(target)}
                                                options={{ closeBoxURL: ``, enableEventPropagation: true, disableAutoPan: true }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: target.colour || "black",
                                                        zIndex: "99",
                                                        marginLeft: "10px",
                                                        marginTop: "10px",
                                                        color: "white",
                                                        borderRadius: "5px",
                                                        fontSize: "12px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }}
                                                ><p style={{ padding: "5px", width: "100%", textAlign: "center" }}>{target.alias || target.name}</p>
                                                </div>
                                            </InfoBox>
                                        </Marker>
                                        <Circle
                                            visible={(showAccuracy[index] || false)}
                                            options={{
                                                strokeColor: ("#3aa202"),
                                                fillColor: ("#4cd403"),
                                                fillOpacity: 0.5
                                            }}
                                            center={{ lat: Number(target.latitude) || 0.0, lng: Number(target.longitude) || 0.0 }}
                                            radius={target.gps_accuracy}
                                        />
                                    </React.Fragment>
                                );
                            }
                        })
                        // .filter(Boolean)
                    }
                    {
                        markersVisibleOnMap && markersVisibleOnMap
                            .map((target, index) => {
                                let batteryData = target.battery_info
                                if ( typeof target.battery_info === "string" ) {
                                    if (batteryData.includes("&&")) {
                                        let battery_info = target.battery_info.split("&&");
                                        batteryData = battery_info[1]
                                    } else {
                                        batteryData = batteryData + "v";
                                    }
                                }
                                console.log(batteryData)
                                return (
                                    index === hover &&
                                    <InfoBox
                                        options={{
                                            closeBoxURL: ``,
                                            enableEventPropagation: true,
                                            pixelOffset: new window.google.maps.Size(-320, -160),
                                            disableAutoPan: true
                                        }}
                                        position={{
                                            lat: target.latitude,
                                            lng: target.longitude,
                                        }}
                                        style={{
                                            boxShadow: "10px 10px 10px 10px #000000",
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid grey",
                                                backgroundColor: "white" || "black",
                                                padding: "20px",
                                                color: "black",
                                                borderRadius: "1em",
                                                fontSize: "15px",
                                                width: "300px",
                                            }}
                                        >
                                            <div>
                                                <h5 style={{ overflowWrap: "anywhere" }}>{target.alias || target.name}</h5>
                                                <p>
                                                    <BatteryFull
                                                        style={{ paddingRight: "10px" }}
                                                        color={target.battery_info > 3.7 ? "green" : target.battery_info > 3.5 ? "orange" : "red"}
                                                        size={25}
                                                    />
                                                    {batteryData}

                                                </p>
                                                <p>
                                                    <GeoAltFill
                                                        style={{ paddingRight: "10px" }}
                                                        size={25}
                                                    />     {target.latitude || 0.0}, {target.longitude || 0.0}
                                                </p>
                                                <p>
                                                    <CarFrontFill
                                                        style={{ paddingRight: "10px" }}
                                                        color={target.speed > 0 ? "green" : "red"}
                                                        size={25}
                                                    />     {convertSpeed('KPH', target.speed)} / {convertSpeed('MPH', target.speed)}
                                                </p>
                                                <p>
                                                    <AddressDisplay lat={target.latitude} lng={target.longitude} />
                                                </p>
                                                <p>
                                                    <ClockFill
                                                        style={{ paddingRight: "10px" }}
                                                        size={25}
                                                    />     {target.position_lastCommsTimestamp ?  `${(new Date(Number(target.position_lastCommsTimestamp) * 1000).toLocaleString())}` : "N/A"}
                                                </p>
                                                <p>
                                                    <HddRackFill
                                                        style={{ paddingRight: "10px" }}
                                                        size={25}
                                                        color={target.lastCommsTimestamp > (Date.now() / 1000) - 60 * 60 * 24 ? "green" : "red"}
                                                    />     {target.lastCommsTimestamp ? `${new Date(Number(target.lastCommsTimestamp) * 1000).toLocaleString()}` : "N/A"}
                                                </p>
                                            </div>
                                        </div>
                                    </InfoBox>
                                )
                            })
                    }
                    {
                        livePinsVisibleOnMap && livePinsVisibleOnMap.map((pin) => {
                            const markerType = {
                                throwIfNamespace: false,
                                path: "M 0 0 L 0 -30 A 10 10 0 1 0 -3 -30 L -3 0 L 0 0Z",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: 'black',
                                fillColor: pin.colour,
                                scale: 1,
                                anchor: new window.google.maps.Point(-1.5, 0),
                            }
                            return (
                                // <div>
                                <Marker
                                    position={{
                                        lat: pin.latitude, lng: pin.longitude
                                    }
                                    }

                                    icon={markerType}
                                    visible={visabiltyCheckPin(pin)}
                                >
                                    < InfoBox
                                        options={{
                                            closeBoxURL: ``,
                                            enableEventPropagation: true,
                                            pixelOffset: new window.google.maps.Size(-150, -80),
                                            disableAutoPan: true
                                        }}
                                        visible={visabiltyCheckPin(pin)}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "white" || "black",
                                                padding: "5px",
                                                color: "black",
                                                borderRadius: "5px",
                                                fontSize: "12px",
                                                width: "300px",
                                            }}
                                        >
                                            <strong>
                                                {pin.name} - {pin.address}
                                            </strong>
                                        </div>
                                    </InfoBox>
                                </Marker>
                            )
                        })
                    }
                    {
                        plots && plots.map((plot, index) => {
                            let markerType = {}
                            if (plot.record_type === 'CELLTOWER') {
                                markerType = {
                                    url: `${getPublicURL()}/broadcast-pin.svg`,
                                    scaledSize: new window.google.maps.Size(30, 30),
                                    fillColor: "#71FC27",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: "#01290D",
                                    rotation: plot.heading,
                                    scale: 4,
                                    anchor: new window.google.maps.Point(14, 14)
                                };
                            } else if (plot.speed < 1) {
                                markerType = {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    fillColor: "#71FC27",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: "#01290D",
                                    rotation: plot.heading,
                                    scale: 5,
                                    anchor: new window.google.maps.Point(0, 0)
                                };
                            } else {
                                markerType = {
                                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                    fillColor: "#71FC27",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: "#01290D",
                                    rotation: plot.heading,
                                    scale: 5,
                                    anchor: new window.google.maps.Point(0, 2)
                                };
                            }
                            return (
                                <Marker
                                    position={{ lat: plot.latitude, lng: plot.longitude }}
                                    icon={markerType}
                                    opacity={(+(map.getZoom() > 15) || plot.record_type !== 'CELLTOWER')}
                                    onMouseOver={() => { setPlotHover([index]); }}
                                    onMouseOut={() => { setPlotHover([]); }}

                                >
                                    {plotHover[0] === index &&
                                        <InfoWindow
                                            position={{
                                                lat: plot.latitude,
                                                lng: plot.longitude,
                                            }}
                                        >
                                            <div>
                                                <p>Marker Latitude: {plot.latitude}</p>
                                                <p>Marker Longitude: {plot.longitude}</p>
                                                <p>Time: {getGMTDateTime(plot.device_utc_date_time)}</p>
                                                <p>Speed: {convertSpeed('KPH', plot.speed)} / {convertSpeed('MPH', plot.speed)}</p>
                                            </div>
                                        </InfoWindow>
                                    }
                                    <Circle
                                        visible={(plot.record_type === 'WIFI' || showAccuracy[index] || false)}
                                        options={{
                                            strokeColor: "#3aa202",
                                            fillColor: "#4cd403",
                                            fillOpacity: 0.5
                                        }}
                                        center={{ lat: Number(plot.latitude) || 0.0, lng: Number(plot.longitude) || 0.0 }}
                                        radius={plot.gps_accuracy}
                                    />
                                </Marker>
                            )
                        })
                    }
                    {
                        geofences && geofences.map((geofence, index) => {
                            if (JSON.parse(geofence.fence).type === "circle") {
                                return (
                                    <Circle
                                        key={index}
                                        center={{
                                            lat: JSON.parse(geofence.fence).coordinates[0][0].lat,
                                            lng: JSON.parse(geofence.fence).coordinates[0][0].lng
                                        }}
                                        radius={JSON.parse(geofence.fence).coordinates[0][1]}
                                    />
                                )
                            } else if (JSON.parse(geofence.fence).type === "rectangle") {
                                return (
                                    <Rectangle
                                        key={index}
                                        bounds={{
                                            north: JSON.parse(geofence.fence).coordinates[0][0][0],
                                            south: JSON.parse(geofence.fence).coordinates[0][2][0],
                                            east: JSON.parse(geofence.fence).coordinates[0][2][1],
                                            west: JSON.parse(geofence.fence).coordinates[0][0][1],
                                        }}
                                    />
                                )
                            }
                        })
                    }
                    {
                        map && map && fence && (
                            <div>
                                <Polygon
                                    paths={fence}
                                    visible={geofenceActive}
                                />
                                <Marker
                                    visible={false}
                                    position={fence[0]}
                                >
                                    <InfoBox
                                        options={{
                                            closeBoxURL: ``,
                                            enableEventPropagation: true,
                                            pixelOffset: new window.google.maps.Size(25, 0),
                                            disableAutoPan: true
                                        }}
                                        visible={geofenceActive}
                                        position={fence[0]}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "white" || "black",
                                                padding: "5px",
                                                color: "black",
                                                borderRadius: "5px",
                                                fontSize: "12px",
                                                width: "20vw",
                                            }}
                                        >
                                            <h5>New GeoFence For: {geofenceDetails.name}</h5>
                                            <Form
                                                onFinish={QuickGeofence}
                                            >
                                                <Form.Item
                                                    label="Geofence Name"
                                                    name="geofenceName"
                                                    rules={[{
                                                        required: true,
                                                        message: "A geofence needs a name"
                                                    }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Users to Alert"
                                                    name="users"
                                                    rules={[{
                                                        required: true,
                                                        message: "At least 1 user is required to be notified"
                                                    }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                    >
                                                        {
                                                            users && users.map((user) => {
                                                                return (
                                                                    <Select.Option value={user.id} key={user.id}>
                                                                        <p>{user.name}</p>
                                                                    </Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Fence Duration"
                                                    name="duration"
                                                    rules={[{
                                                        required: true,
                                                        message: "Fence must have a length"
                                                    }]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value={172800}>48 Hours</Radio>
                                                        <Radio value={86400}>24 Hours</Radio>
                                                        <Radio value={43200}>12 Hours</Radio>
                                                        <Radio value={21600}>6 Hours</Radio>
                                                        <Radio value={10800}>3 Hours</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item
                                                    name="timezone"
                                                    label="Timezone"
                                                    rules={[{
                                                        required: true,
                                                        message: "Please select a timezone"
                                                    }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear={true}
                                                    >
                                                        {getTimeZones().map((timezone) => (
                                                            <Select.Option key={timezone.value}>{timezone.label}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Button
                                                    style={secondaryButton({ width: "47%" })}
                                                    onClick={() => setGeofenceActive(false)}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    style={primaryButton({ width: "47%" })}
                                                    htmlType="submit"
                                                >
                                                    Submit
                                                </Button>
                                            </Form>
                                        </div>
                                    </InfoBox>
                                </Marker>
                            </div>)
                    }

                    {
                        activeShape === 'rectangle' && (
                            <Rectangle
                                bounds={
                                    rectangleBounds
                                }
                            />
                        )
                    }
                    {
                        activeShape === 'circle' && (
                            <Circle
                                center={center}
                                radius={radius}
                            />
                        )
                    }
                    {
                        cellTower && cellTower.lastCellPoint && (
                            <Circle
                                center={{
                                    lat: cellTower.lastCellPoint.CELL_LAT,
                                    lng: cellTower.lastCellPoint.CELL_LNG,
                                }}
                                radius={cellTower.lastCellPoint.CELL_ACCURACY}
                                options={{
                                    strokeColor: "#7faedc",
                                    fillColor: "#A7C7E7",
                                    fillOpacity: 0.5
                                }}
                            />
                        )
                    }
                    {
                        cloneCapturePoint && cloneCapturePoint.capture_type && (
                            <div>

                                <Marker
                                    position={{
                                        lat: cloneCapturePoint.latitude,
                                        lng: cloneCapturePoint.longitude,
                                    }}
                                    icon={{

                                        url: `${getPublicURL()}/clone.png`,
                                        scaledSize: new google.maps.Size(35, 35),

                                    }}
                                >
                                 <InfoWindow
                                    position={{
                                        lat: cloneCapturePoint.latitude,
                                        lng: cloneCapturePoint.longitude,
                                    }}
                                    >
                                        <div>
                                           <p>{`Capture Type: ${cloneCapturePoint.capture_type}`}</p>
                                            <p>{`Time: ${new Date(cloneCapturePoint.timestamp * 1000).toLocaleTimeString()}`}</p>
                                        </div>
                                    </InfoWindow>
                                </Marker>
                                <Marker
                                    icon={
                                        {
                                            path: window.google.maps.SymbolPath.CIRCLE,
                                            fillColor: "#27B8FC",
                                            fillOpacity: 1,
                                            strokeWeight: 1,
                                            strokeColor: "#01290D",
                                            rotation: 0,
                                            scale: 5,
                                            anchor: new window.google.maps.Point(0, 0)
                                        }
                                    }
                                    position={{
                                        lat: cloneCapturePoint.firstPoint.lat,
                                        lng: cloneCapturePoint.firstPoint.lng,
                                    }}
                                />
                                <Polyline
                                    path={[
                                        {
                                            lat: cloneCapturePoint.targetloc.lat,
                                            lng: cloneCapturePoint.targetloc.lng,
                                        },
                                        {
                                            lat: cloneCapturePoint.latitude,
                                            lng: cloneCapturePoint.longitude,
                                        },
                                        {
                                            lat: cloneCapturePoint.firstPoint.lat,
                                            lng: cloneCapturePoint.firstPoint.lng,
                                        }
                                    ]}
                                    zIndex={3}
                                    options={{
                                        strokeColor: "#03B83C",
                                        strokeOpacity: 0.75,
                                        strokeWeight: 3,
                                    }}
                                />
                            </div>
                        )
                    }
                    < TrafficLayer autoUpdate />
                </GoogleMap >
                :
                <Spin></Spin>
            }
        </div >
    );
});

export default observer(LiveMapBEN);