import {action, computed, observable, makeAutoObservable} from "mobx";
import {createContext} from "react";
import {toJS} from "mobx";
// Services
import API from "../services/api";
import {message} from "antd";

export class LivePinStore {

    constructor() {

        // List pins by investigation
        this.livePinsByInvestigation = []

        // Pins to be hidden
        this.hiddenPins = [];

        // Show live pins
        this.livePins = [];

        // newly adding pin
        this.pinToAdd = [];

        // Boolean for data
        this.hasNoData = false;

        // Currently selected live pin
        this.selectedLivePin = null;

        this.api = API;
        makeAutoObservable(this);

    }

    setSocketData(data) {
        let livePin = toJS(this.livePins).filter(x => x.id === Number(data.id));


        if(livePin.length > 0){
            this.livePins = toJS(this.livePins).filter(x => x.id !== Number(data.id))
        }
        this.getLivePins();
    }

    async getLivePins() {
        const response = await this.api.livePin.getLivePins();
        let investigationPins = response.livePin;

        let filteredLivePinsByInvestigation = []
        if(investigationPins.length > 0){
            investigationPins.forEach((item) => {

                let filteredPins = []
                let pinsToAdd = item.pins.map(x => {
                    x.colour = item.colour
                    return x;
                })
                filteredPins = filteredPins.concat(pinsToAdd);
                this.livePins = this.livePins.concat(pinsToAdd);
                item.pins = filteredPins;
                filteredLivePinsByInvestigation.push(item);
            });
            this.livePinsByInvestigation = filteredLivePinsByInvestigation;
        }else{
            this.hasNoData = true;
        }
    }

    isLivePinCurrentlyHidden(pin) {
        return (
            this.hiddenPins.filter((t) => t.id === pin.id)
                .length > 0
            // false
        );
    }

    toggleVisibility(pin) {
        const currentMarkerHidden = this.hiddenPins.some((hiddenMarker) => {
            // eslint-disable-next-line
            return hiddenMarker.id === pin.id;
        });

        if (currentMarkerHidden) {
            this.hiddenPins = this.hiddenPins.filter(function (hiddenMarker) {
                // eslint-disable-next-line
                return hiddenMarker.id !== pin.id;
            });

            // message.info(`Showing target: ${marker.name}`);
        }
        else {
            this.hiddenPins.push(pin);

            // message.info(`Hiding target: ${marker.name}`);
        }
    }

    get filteredLivePins() {
        const markersToShow = [];
        this.livePins.forEach((pin) => {
            if (this.isLivePinCurrentlyHidden(pin)) {
            } else {
                markersToShow.push(pin);
            }
        });

        return this.livePins;
    }

    setSelectedLivePin(pin) {
        if (pin.latitude !== null && pin.longitude !== null) {
            this.selectedLivePin = pin;
        } else {
            message.warn("Live Pin does not have coordinates.");
        }
    }

    setSelectedInvestigation(investigation, queryLocation) {
        if (this.pinToAdd.length > 0) {
            this.pinToAdd = [];
        }


        this.pinToAdd.push({
            investigation_name: investigation.name,
            investigation_id: investigation.id,
            latitude: queryLocation.lat || 0,
            longitude: queryLocation.lng || 0,
            colour: investigation.colour
        })

    }

    async addLivePin(name, type, lat, lng){
        try {
            let {investigation_id, latitude, longitude} = this.pinToAdd[0];
            await this.api.livePin.addLivePin({
                investigation_id: investigation_id,
                latitude: lat,
                longitude: lng,
                name: name,
                type: type
            });
            message.success(`Live Pin  has been successfully added.`);
            this.pinToAdd = []
            await this.getLivePins();
        } catch (exception) {
            console.log(exception);
            message.error(`${exception.response.data.message}`);
        }
    }

    async removeLivePin(id) {
        try {
            await this.api.livePin.removeLivePin(id);
            this.livePins = toJS(this.livePins).filter(x => x.id !== id)
            message.success(`Live Pin  has been successfully removed.`);
            await this.getLivePins();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }
}

export default createContext(new LivePinStore());