import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const getNotificationSettings = async () => {
    try {
        const response = await HTTP.get("/user-notification-setting/");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.userNotificationSetting;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getNotificationFields = async () => {
    try {
        const response = await HTTP.get("/alert-type/");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.alertType;
        console.log(data);
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateNotificationSettings = async (notificationSettings) => {
    try {
        const response = await HTTP.post("/user-notification-setting/", notificationSettings);
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getNormalizedFieldAndValues = async () => {
    const [fields, values] = await Promise.all([
        getNotificationFields(),
        getNotificationSettings(),
    ]);
    const displayArray = [];
    fields.forEach((field) => {
        const fieldvalue = {
            alertType: field,
        };
        const value = values.find((value) => value.alert_type_id === field.id);
        if (value) {
            fieldvalue.email = !!value.email;
            fieldvalue.sms = !!value.sms;
            fieldvalue.push_notification = !!value.push_notification;
            fieldvalue.web = !!value.web;
        } else {
            fieldvalue.email = false;
            fieldvalue.sms = false;
            fieldvalue.push_notification = false;
            fieldvalue.web = false;
        }
        displayArray.push(fieldvalue);
    });
    displayArray.sort((a, b) => {
        if (a.alertType.order_id > b.alertType.order_id) {
            return 1;
        }
        if (a.alertType.order_id < b.alertType.order_id) {
            return -1;
        }
        if (a.alertType.order_id === b.alertType.order_id) {
            return 0;
        }
    });
    return Promise.resolve(displayArray);
};

export default {
    getNotificationSettings,
    getNotificationFields,
    updateNotificationSettings,
    getNormalizedFieldAndValues,
};
