import React, {useState} from 'react';
import moment from 'moment';
import { Form, Tooltip, InputNumber, Switch, Slider, Select, Row, Col, DatePicker, Radio, Checkbox, Segmented, TimePicker, Divider, Input } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';

const has = Object.prototype.hasOwnProperty;
const SLIDER_SEGMENTS = 4;

function fieldFactory(getFieldDecorator, field, initialValue) {
    if (initialValue === undefined || initialValue === null) {
        initialValue = field.props.default;
    }
    let options = { initialValue: initialValue }
    let config = {
        min: (field.props.min === undefined ? undefined : field.props.min),
        max: (field.props.max === undefined ? undefined : field.props.max),
    }
    // console.log(field);
    switch (field.type) {

        case 'int': {
            config.step = (field.props.step === undefined ? 1 : field.props.step);
            options.rules = [{
                min: config.min,
                max: config.max,
                type: 'number',
                transform: (value) => parseInt(value),
            }];

            return (<React.Fragment>
                {getFieldDecorator(field.name, options)(<InputNumber type='number' {...config} />)}
            </React.Fragment>);
        }

        case 'float': {
            config.precision = (field.props.precision === undefined ? 2 : field.props.precision);
            config.step = (field.props.step === undefined ? 0.01 : field.props.step);
            options.rules = [{
                min: config.min,
                max: config.max,
                type: 'number',
                transform: (value) => parseFloat(value),
            }];

            return (<React.Fragment>
                {getFieldDecorator(field.name, options)(<InputNumber type='number' {...config} />)}
            </React.Fragment>);
        }

        case 'bool': {
            if (has.call(field.props, 'options') && field.props.options !== undefined) {
                options.rules = [{
                    type: 'enum',
                    enum: field.props.options.reduce((prev, curr) => {
                        prev.push(curr.value);
                        return prev;
                    }, []),
                    transform: (value) => field.props.options[(value ? 1 : 0)].value,
                }];
            } else {
                options.rules = [{
                    type: 'boolean',
                    transform: (value) => Boolean(value),
                }];
            }

            if (has.call(field.props, 'text') === false) {
                field.props.text = ["No", "Yes"];
            }
            options.valuePropName = 'checked';
            return (<React.Fragment>
                {getFieldDecorator(
                    field.name,
                    options,
                )(<Switch
                    defaultChecked={initialValue}
                    unCheckedChildren={field.props.text[0]}
                    checkedChildren={field.props.text[1]}
                />)}
            </React.Fragment>);
        }

        case 'slider': {

            // Create marks according to how may slider segments are defined
            const maxValue = field.props.options.length - 1;
            const markSpacing = Math.round(field.props.options.length / SLIDER_SEGMENTS);
            const marks = field.props.options.reduce((prev, curr, idx) => {
                if (
                    idx === 0
                    || idx === field.props.options.length - 1
                    || idx % markSpacing === 0
                ) {
                    prev[idx] = { label: curr.name };
                }
                return prev;
            }, {});

            options.rules = [{
                min: 0,
                max: maxValue,
                type: 'number',
            }]

            return (<React.Fragment>
                {getFieldDecorator(
                    field.name,
                    options,
                )(
                    <Slider
                        tipFormatter={(value) => field.props.options[value].name}
                        min={0}

                        max={maxValue}
                        included={true}
                        marks={marks}
                    />)}
            </React.Fragment>);
        }
        case 'DatePicker': {
            options = { initialValue: moment(initialValue) || moment()};
            return (<React.Fragment>
                {getFieldDecorator(
                    field.name,
                    options
                )(<DatePicker
                    showTime
                />)}
            </React.Fragment>);
        }

        case 'Radio': {
            // convert bit field to array of options
            // const optionsArray = [];
            // for (let i = 0; i < field.props.options.length; i++) {
            //     if ((initialValue & (1 << i)) !== 0) {
            //         optionsArray.push(field.props.options[i].value);
            //     }
            // }
            // options.initialValue = optionsArray;
            if (!Array.isArray(options.initialValue)) {
                options.initialValue = [];
            }
            return (<React.Fragment>
                {getFieldDecorator(
                    field.name,
                    options,
                )(<Checkbox.Group
                    options={field.props.options.map(index => {
                        return { label: index.name, value: index.value }
                    })}

                />)}
            </React.Fragment>);
        }

        case 'string': {
             options.rules = [{
                type: 'string',
                transform: (value) => String(value),
            }];
            return (<React.Fragment>
                {getFieldDecorator(
                    field.name,
                    options,
                )(<Input type='string' {...config} />)}
            </React.Fragment>);
        }

        case 'select': {
            console.log(field);
            options.rules = [{
                type: 'enum',
                enum: field.props.options.reduce((prev, curr) => {
                    prev.push(curr.value);
                    return prev;
                }, []),
            }]

            return (<React.Fragment>
                {getFieldDecorator(
                    field.name,
                    options,
                )(<Select>
                    {field.props.options.map((option, idx) => (
                        <Select.Option key={idx} value={option.value}>{option.name}</Select.Option>
                    ))}
                </Select>)}
            </React.Fragment>);
        }

        case 'segment': {
            return (<React.Fragment>
                {
                    getFieldDecorator(field.name, options)(
                        <Segmented
                            options={
                                field.props.options.map((option, idx) => {
                                    return {
                                        value: idx,
                                        label: option.name
                                    }
                                })
                            }
                        />
                    )
                }
            </React.Fragment>)
        }

        case 'RadioFreqSelect': {
            config.step = (field.props.step === undefined ? 1 : field.props.step);
            options.rules = [{
                transform: (value) => parseFloat(value).toFixed(4),
            }];
            options.initialValue = parseFloat(options.initialValue.toString()).toFixed(4);
            console.log(options)
            return (<React.Fragment>
                {getFieldDecorator(field.name, options)(<Input disabled={true} />)}
            </React.Fragment>);

            // function Component() {
            //     const [frequences, setFrequences] = useState([433, 868, 915]);
            //     return (<React.Fragment>
            //         <Switch
            //             onChange={(checked) => {
            //                 setFrequences(checked ? [433, 868, 915] : [433, 868]);
            //             }}
            //         ></Switch>
            //         {getFieldDecorator(field.name, options)(
            //             <Slider marks={frequences} />
            //         )}
            //     </React.Fragment>);
            // }
            // return Component();

        }

        case 'divider': {
            return (
                <React.Fragment>
                    <Divider
                    />
                </React.Fragment>
            )
        }

        case 'DatePickerMM': {
            if (initialValue === 0) {
                initialValue = "00:00";
            }
            options = { initialValue: moment(initialValue, "HH:mm") || moment("00:00", "HH:mm")};
            return (
                <React.Fragment>
                    {
                        getFieldDecorator(field.name, options)(
                            <TimePicker
                                format="mm"
                                />
                        )
                    }
                </React.Fragment>
            )
        }

        case 'DatePickerHHMM': {
            if (initialValue === 0) {
                initialValue = "00:00";
            }
            options = { initialValue: moment(initialValue, "HH:mm") || moment("00:00", "HH:mm")};
            return (
                <React.Fragment>
                    {

                        getFieldDecorator(field.name, options)(
                            <TimePicker
                                format="HH:mm"
                            />
                        )
                    }
                </React.Fragment>
            )
        }

        default: {
            throw new Error(`Unknown field type: ${field.type}`);
        }
    }
}

export function deviceConfigFieldFactory(field, initialValue, getFieldDecorator, opts={}) {
    const description = field.description || field.desc || null;

    const tooltip = (
        <span style={{ marginLeft: '0.5em', width: '1em', height: '1em' }}>
            <Tooltip title={description}>
                <QuestionCircleTwoTone twoToneColor="#27ae60" />
            </Tooltip>
        </span>
    );
    const label = field.displayName || field.name;
    let labelWithTooltip = (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <span style={{ whiteSpace: 'normal' }}>{label}</span>
            {tooltip}
        </div>
    )
    if (field.type === 'divider') {
        labelWithTooltip = (
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <span style={{ whiteSpace: 'normal' }}></span>
                <Divider />
            </div>
        );
        return (
            labelWithTooltip
        )
    }

    return (
        <React.Fragment>
            <Form.Item
                {...opts}
                key={field.name}
                label={labelWithTooltip}
                name={String(field.name)}
                colon={true}
            >{fieldFactory(getFieldDecorator, field, initialValue)}
            </Form.Item>
        </React.Fragment>
    )
}
