import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const GetAllEntities = async (investigation, clientID) => {
    if (investigation === null || investigation === undefined) return ;
    try {
    //const offset = 60 * 30;
        const response = await HTTP.get(`/entity/${investigation}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.entity;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }

};

const deleteEntity = async (id) => {
    try {
        const response = await HTTP.post(`/entity/delete/${id}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.entity;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const pageEntity = async (id) => {
    try {
        const response = await HTTP.get(`/entity/page/${id}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.entity;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const updateEntity = async (id, alias, notes) => {
    console.log("here");
    try {
        const response = await HTTP.post(`/entity/update/${id}`, {
            alias: alias,
            notes: notes,
        });

        return Promise.resolve(response);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const createEntity = async (investigation, alias, notes) => {
    try {
        const response = await HTTP.post(`/entity/create?investigationID=${investigation}&alias=${alias}&notes=${notes}`);
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}

const getEntityDetails = async (id) => {
    try {
        const response = await HTTP.get(`/entity/Details/${id}`);
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}

const theMonity = async () => {
    const response = await HTTP.get('/entity/monity');
    const data = response.data.data.entity;
    console.log(data);

}

export default {
    deleteEntity,
    GetAllEntities,
    pageEntity,
    updateEntity,
    createEntity,
    theMonity,
    getEntityDetails,
};
