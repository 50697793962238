import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const DoTheGeoCoder = async (lat, lng) => {
    try {
        const response = await HTTP.post("/geoCoding/", {
            lat, lng
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.geo_address;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    DoTheGeoCoder,
};
