// import { notification } from "antd";
import { message } from "antd";
import FuzzySearch from "fuzzy-search";
import { action, computed, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;
const has = Object.prototype.hasOwnProperty;

export class DeviceStore {
    constructor() {
        this.devices = [];
        this.unassignedDevices = [];
        this.deviceTypes = [];
        this.searchQuery = "";
        this.api = API;
        makeAutoObservable(this);
    }

    get results() {
        if (this.searchQuery) {
            const searcher = new FuzzySearch(
                this.devices,
                [
                    "serial",
                    "target_name",
                    "investigation_name",
                    "manufacturer",
                    "model",
                    "device_code",
                ],
                {
                    caseSensitive: false,
                }
            );
            return searcher.search(this.searchQuery.trim());
        } else {
            return this.devices;
        }
    }

    async getAllDevices() {
        const response = await this.api.device.getAllDevices();
        const promises = response.map(async (device) => {
            let deviceConfiguration = await this.getRecoveryStatus(device.id);
            if (deviceConfiguration !== undefined) {
                let settingsObject = deviceConfiguration[0].settings;

                // Backwards compatibility with old settings object construct
                // DEPR: Remove this when all devices have been updated to new format
                if (typeof deviceConfiguration[0].settings === "string") {
                    settingsObject = JSON.parse(deviceConfiguration[0].settings);
                }

                if (has.call(settingsObject, 'settings')) {
                    console.log('DEPRECATION WARNING: Device configuration is using deprecated settings field. Please update your device.');
                    settingsObject = settingsObject.settings;
                }

                device.recovery_settings = Number(settingsObject.recovery);
                device.recovery_created_at = deviceConfiguration[0].created_at;
                device.recovery_updated_at = deviceConfiguration[0].updated_at;
                device.recovery_status = deviceConfiguration[0].status;
                device.device_updated_at = deviceConfiguration[0].device_updated_at;
            } else {
                device.recovery_settings = device.recovery_settings || null;
                device.recovery_created_at = device.created_at || null;
                device.recovery_updated_at = device.updated_at || null;
                device.recovery_status = device.status || null;
                device.device_updated_at = device.device_updated_at || null;
            }

            if (device.battery_info && !isNaN(device.battery_info)) {
                const batteryInfo = JSON.parse(device.battery_info);
                device.device_voltage_filtered = (batteryInfo.internalVoltage / 1000);
            } else {
                device.device_voltage_filtered = null;
            }

            return device;
        });
        const results = await Promise.all(promises);
        this.devices = results;
    }

    async getAllUnassignedDevices() {
        const unassignedDevices = await this.api.device.getAllUnassignedDevices();
        const allDevices = await this.api.device.getAllDevices();

        const isDeviceUnassigned = (id) => {
            return unassignedDevices.filter((t) => id === t.id).length > 0;
        };

        const mergedDevices = allDevices.map((device) => {
            return {
                id: device.id,
                lastCommsTimestamp: device,
                name: `${device.model}-${device.serial}`,
                unassigned: isDeviceUnassigned(device.id),
            };
        });

        this.unassignedDevices = mergedDevices;
    }

    async getDeviceTypes() {
        const response = await this.api.device.getDeviceTypes();
        this.deviceTypes = response;
    }

    async createDevice({ serial, device_type_id, license }) {
        try {
            await this.api.device.createDevice({
                serial,
                device_type_id: Number(device_type_id),
                license
            });
            message.success(`Device  has been created.`);
            this.getAllDevices();
            this.getAllUnassignedDevices();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async deleteDevice(record) {
        try {
            await this.api.device.deleteDevice(record.id);
            message.success(
                `Device ${record.manufacturer} ${record.model} - ${record.serial} has been deleted.`
            );
            this.getAllDevices();
            this.getAllUnassignedDevices();
        } catch (exception) {
            if (
                exception.response &&
        exception.response.data.code === "DEVICE_IN_USE"
            ) {
                message.error(
                    `Device ${record.manufacturer} ${record.model} - ${record.serial} is currently in use.`
                );
            } else {
                message.error(
                    `Device ${record.manufacturer} ${record.model} - ${record.serial} could not be deleted.`
                );
            }
        }
    }

    async sendRecoveryModeDetails(device, recoveryTime, deviceType) {
        const data = {
            config_id: 1,
            device_id: device.id,
            settings: {
                recovery: recoveryTime,
            },
            device_type: deviceType
        };

        try {
            await this.api.device.postDeviceConfiguration(device.id, data);
            if (recoveryTime === 300) {
                await this.api.device.updateDeviceConfigurationMode({ id: device.id, configuration_mode: 'MONITORING' });
            } else {
                await this.api.device.updateDeviceConfigurationMode({ id: device.id, configuration_mode: 'LIVE' });
            }

            message.success(
                `Device live mode activation successful for ${device.model}-${device.serial}.`
            );
        } catch (exception) {
            message.error(
                `Device live mode activation unsuccessful for ${device.model}-${device.serial}. ${exception.message}`
            );
        }
    }

    async getRecoveryStatus(id) {
        try {
            return await this.api.device.getRecoveryStatus(id);
        } catch (exception) {
            // console.log(exception);
        }
    }

    getDeviceBySerial(serial) {
        const val = this.devices.find((device) => {
            if (device.serial === serial) {
                return device;
            }
        });
        return val;
    }
}

export default createContext(new DeviceStore());
