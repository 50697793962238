import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import API from "../services/api";

export class entityPageStore {
    constructor () {
        this.type = null;
        this.trip = null;
        this.selectedEntity = null;
        this.entityList = [];
        this.combainedView = false;
        this.entity = null;
        this.combainedTrips = [];
        makeAutoObservable(this)
    }

    updateEntityList (investId) {
        API.entity.GetAllEntities(investId).then((res) => {
            this.entityList = res;
        })
    }

    removeEntityFromArray(entityId) {
        this.entityList = this.entityList.filter((entity) => {
            return entity.id != entityId;
        });
    }

    createEntity (entity) {
        API.entity.createEntity(entity.InvestigationID, entity.nickname, entity.notes).then((res) => {
            this.updateEntityList(this.trip);
        })
    }

    setEntity (entity) {
        this.entity = entity;
        this.combainedView = false;
    }

    setType (type) {
        this.type = type;
    }

    setTrip (trip) {
        this.trip = trip;
    }

    setLatLng(latlng) {
        this.selectedEntity.latitude = latlng.lat;
        this.selectedEntity.longitude = latlng.lng;
    }

    setCombainedView() {
        this.combainedView = !this.combainedView;
        if (this.combainedView) {
            this.combainedTrips = [];
            this.entity.Devices.forEach((record) => {
                console.log(record);
                API.CloneCapture.historyDevice(record.id).then((res) => {
                    // date format
                    let len = res.length;
                    for (let i = 0; i < len; i += 1) {
                        let date = new Date(0);
                        date.setUTCSeconds(res[i].timestamp)
                        res[i].timeStamp = date.toString();
                    }
                    const temp = this.combainedTrips;
                    temp.push(...res);
                    temp.sort((a, b) => {
                        return b.timestamp - a.timestamp;
                    });
                    this.combainedTrips = temp;
                })
            });
        }
    }

    selectEntity (entity) {
        this.selectedEntity = entity;
    }

    get displayType () {
        return this.type;
    }

    get displayTrip () {
        return this.trip;
    }

    get displaySelectedEntity () {
        return this.selectedEntity;
    }

    get displayEntityList () {
        console.log(this.entityList);
        return this.entityList;
    }
}

export default createContext(new entityPageStore());