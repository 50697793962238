import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import {createContext, useEffect} from "react";
import API from "../services/api";
import {macToMAC} from "../util/CommonUtils";

export class WifiNetworkStore {
    wifiNetworks = [];
    selectedAps = [];
    hiddenAps = [];
    searchText = "";
    filterSetting = 1;
    investigationId = -1;
    DragList = [];
    entityFilter = [];

    _displayAps = [];

    loading = false;

    constructor() {
        this.api = API;
        makeAutoObservable(this);
    }

    setInvestigationId(id) {
        this.investigationId = id;
    }

    async getWifiNetworks() {
        try {
            const response = await API.accessPoint.accessPointsByInvestigation(this.investigationId)
            console.log(response);
            this.wifiNetworks = response;
            this._displayAps = response;
        } catch (error) {
            console.log(error)
            message.error(error.message);
        }
    }

    setEntityFilter(entity) {
        this.entityFilter = entity;
    }

    isWifiCloned(captureType) {
        return captureType === "WIFI_CLONE_SCAN" || captureType === 'WIFI_FRAME_FUNCTION_AUTHENTICATION' || captureType === 'CLONE_AND_SNIFF';
    }
    filterEntity(captures) {
        if (this.entityFilter.length === 0) return captures;
        const returnArray = [];
        for (let i = 0; i < captures.length; i++) {
            const ap = captures[i];
            const devices = ap.devices;
            for (let j = 0; j < devices.length; j++) {
                const device = devices[j];
                if (device.entity_id === null || device.entity_id === -1) continue;
                if (this.entityFilter.includes(device.entity_id)) {
                    returnArray.push(ap);
                    break;
                }
            }
        }
        return returnArray;
    }


    get displayAps() {
        const thing = this.filterEntity(this.wifiNetworks);
        if (!thing) return null;
        switch (this.filterSetting) {
            case 1:
                return thing.filter((ap) => ap.devices.length > 0);
            case 4:
                return thing;
            case 2:
                return thing.filter((ap) => ap.capture_type !== 'WIFI_SNIFF_SCAN');
            case 3:
                return this.faveAps;
        }
        return thing;
    }

    get faveAps() {
        return this.wifiNetworks.filter((ap) => {if (ap.metadata) {
            let obj = JSON.parse(ap.metadata)
            return !!obj.favourited;
        }});
    }

    addApToHidden(ap) {
        this.hiddenAps.push(ap);
    }

    get displaySelectedAps() {
        let thing = [];
        if (this.searchText !== null && this.searchText !== "") {
            let newThing = this.wifiNetworks.filter((ap) => {
                return (
                    ap.ssid?.toLowerCase().includes(this.searchText.toLowerCase()) |
                    macToMAC(ap.mac_address)?.toLowerCase().includes(this.searchText.toLowerCase())
                )
            });
            thing = newThing;
        }
        if (this.selectedAps.length > 0) {
            thing = thing.concat(this.selectedAps);
        }
        return thing;
    }

    clearSelected() {
        this.searchText = null;
    }

    removeApFromHidden(ap) {
        this.hiddenAps = this.hiddenAps.filter((item) => item !== ap);
    }

    addApToSelected(ap) {
        if (this.selectedAps === null) {
            this.selectedAps = [];
        }
        if (this.selectedAps.includes(ap)) {
           this.selectedAps = this.selectedAps.filter((item) => item !== ap);
        } else {
            this.selectedAps.push(ap);
        }
    }

    removeApFromSelected(ap) {
        this.selectedAps = this.selectedAps.filter((item) => item !== ap);
    }

    addDragList(ap) {
        this.DragList.push(ap);
    }

    removeDragList(ap) {
        this.DragList = this.DragList.filter((item) => item !== ap);
    }

    setFilter(setting) {
        this.filterSetting = setting;
    }

}

export default createContext(new WifiNetworkStore());
