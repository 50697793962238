import React from "react";
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Layout, message } from "antd";
import { deviceConfigFieldFactory } from '../../factories/deviceConfigField';
import { primaryButton, secondaryButton } from '../../styles';
import Status from './status';
import { has } from '../../util/CommonUtils';
import moment from "moment";

class CategoryConfigurationForm extends React.Component {

    constructor(props) {
        super(props);

        // Parse default configuration from schema
        let defaultValues = {};
        this.props.category.fields.forEach((field) => {

            // Dereference local category option fields
            if (has.call(field.props, 'options') && typeof field.props.options === 'string') {
                field.props.options = this.props.category.options[field.props.options];
            }

            defaultValues[field.name] = this.normalizeInput(field, field.props.default);
        });

        this.state = { defaultValues };
    }

    // Normalize initial values for form field inputs
    normalizeInput(field, initialValue) {
        let returnValue = initialValue;
        // Convert option value to option index
        if (has.call(field.props, 'options') && field.props.options !== undefined) {

            // Find index of the initial value option
            if (field.type === 'Radio') {
                const convertedArray = [];
                // console.log(initialValue);
                // console.log(typeof initialValue);
                if (typeof initialValue === 'string') {
                    initialValue = initialValue.replace("[", "").replace("]", "").replace("", "").split(",");
                    // console.log(initialValue);
                    let thing = [];
                    initialValue.forEach((value) => {
                        if(value !== "") {
                            thing.push(parseInt(value.replace(" ", '')));
                        }
                    });
                    initialValue = Array.from(thing);
                }
                if(initialValue !== 0 && initialValue !== undefined && initialValue !== null) {
                    // console.log(initialValue);
                    // console.log(field.props.options);
                    // console.log(Array.isArray(initialValue));
                    if (!Array.isArray(initialValue)) return [];
                    initialValue.forEach((value) => {
                        // console.log(value)
                        const index = field.props.options.findIndex(opt => (value == opt.value));
                        if (index >= 0) {
                            // console.log(field.props.options[index]);
                            convertedArray.push(field.props.options[index].value);
                        }
                    });
                    returnValue = convertedArray;
                    // console.log(returnValue);
                    return returnValue;
                }
            }
            returnValue = field.props.options.findIndex(opt => (initialValue == opt.value));
            if (returnValue < 0) {
                console.log(field);
                console.log(initialValue);
                console.log(field.props.options);
                console.log(field.name);
                returnValue = field.props.default;
                // throw new Error(`Invalid default value ${returnValue} for field ${field.name}`);
            }
            if(field.type === "segment" || field.type === "select"){
                console.log(field.props.options[returnValue].value);
                returnValue = field.props.options[returnValue].value;
            }
        }

        // Convert 1/0 to true/false
        if (field.type === 'bool') {
            returnValue = Boolean(returnValue);
        }
        if(field.type === 'DatePickerHHMM'){
            // convert seconds to HH:MM string
            const hours = Math.floor(initialValue / 3600);
            const RemainMins = ( initialValue - (hours * 3600) );
            const minutes = Math.floor(RemainMins / 60);
            if(hours < 10 && minutes < 10){
                const string = `0${hours}:0${minutes}`;
                returnValue = string;
            } else if(hours < 10){
                const string = `0${hours}:${minutes}`;
                returnValue = string;
            } else if(minutes < 10){
                const string = `${hours}:0${minutes}`;
                returnValue = string;
            } else {
                const string = `${hours}:${minutes}`;
                returnValue = string;
            }
            returnValue = moment(returnValue, "HH:mm");
        }

        return returnValue;
    }

    // Normalize field values for submission to API
    normalizeFields(data) {
        console.log(data);
        // Normalize option field index value, to specified option value
        this.props.category.fields.forEach((field) => {
            if (field.visible === false) {
                return;
            }
           // console.log(field);

            // Ignore select fields as they are enumerated, and do not require option mapping like
            // sliders or switches
            if (has.call(field.props, 'options') && field.type !== 'select' && field.type !== 'Radio') {
                if (typeof field.props.options === 'string') {

                    // Derefence from local category options fields
                    const categoryOpts = this.props.category.options[field.props.options];
                    if (has.call(categoryOpts[data[field.name]], 'value')) {
                        data[field.name] = categoryOpts[data[field.name]].value;
                    } else {
                        throw new Error(`Field option array does not specify a value for field ${field.name}`);
                    }
                } else {
                    console.log(data[field.name]);
                    console.log(field.props.options);
                    console.log(field.props.options[data[field.name]]);
                    data[field.name] = field.props.options[data[field.name]].value;
                }
            } else if (field.type === 'boolean') {
                console.log(field);
                data[field.name] = Boolean(data[field.name]);
            } else if (field.type === 'DatePickerHHMM') {
                console.log("data time init")
                data[field.name] = data[field.name].format('HH:mm');
                // convert hh:mm string to seconds
                const time = data[field.name].split(':');
                const seconds = (+time[0]) * 60 * 60 + (+time[1]) * 60;
                data[field.name] = seconds;
                console.log(data[field.name]);
            }

        })
        return data;
    }

    render() {
        return (
            <Layout style={{ padding: '20px', borderRadius: '10px' }}>
                {this.props.deviceConfig !== null ?
                    <Status
                        status={Number(this.props.deviceConfig.status)}
                        deviceUpdatedDate={this.props.deviceConfig.device_updated_at}
                        configurationUpdatedDate={this.props.deviceConfig.update_at}
                    />
                    : []}
                <Form
                    layout={'horizontal'}
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.props.form.validateFields()
                            .then((values) => {
                                console.log(values);
                                const data = this.normalizeFields(values);
                                this.props.onSubmit(data);
                            })
                            .catch((err) => {
                                console.log(err);
                                message.error('Form input is invalid.');
                            });
                    }}
                >
                    {this.props.category.fields.map((field) => {

                        // Ignore hidden fields
                        if (has.call(field, 'visible') && field.visible === false) {
                            return [];
                        }

                        // Ignore advanced fields if modal is in basic mode
                        if (
                            this.props.advanced === false
                            && has.call(field, 'advanced')
                            && field.advanced === true
                        ) {
                            return [];
                        }

                        let initialValue = field.props.default;

                        // Load initial value from device configuration if it exists
                        if (
                            this.props.deviceConfig !== null
                            && has.call(this.props.deviceConfig, 'settings')
                        ) {
                            // Backwards compatibility for old device configs
                            // DEPR: Remove this when all devices are updated
                            if (has.call(this.props.deviceConfig.settings, 'settings')) {
                                console.log('DEPRECATION WARNING: Device configuration is using deprecated settings field. Please update your device.');
                                initialValue = this.props.deviceConfig.settings.settings[field.name];
                            } else {
                                initialValue = this.props.deviceConfig.settings[field.name];
                            }
                        }
                        // Normalize initial value for form inputs, and store field default state
                        initialValue = this.normalizeInput(field, initialValue);
                        // Generate field component
                        console.log(initialValue);
                        return deviceConfigFieldFactory(
                            field,
                            initialValue,
                            this.props.form.getFieldDecorator,
                            { labelCol: { span: 8 }, wrapperCol: { span: 15 } },
                        );
                    })}
                    <div style={{ display: 'inline' }}>
                        <Button
                            type="secondary"
                            style={secondaryButton({ margin: '0.3em', backgroundColor: '#f39c12', color: 'white' })}
                            onClick={() => {
                                console.log(this.state.defaultValues);
                                this.props.form.setFieldsValue(this.state.defaultValues)}
                        }
                        >
                            Reset to Default
                        </Button>
                    </div>
                    <div style={{ float: 'right', display: 'inline' }}>
                        <Button
                            type="secondary"
                            style={secondaryButton({ margin: '0.3em' })}
                            onClick={() => this.props.form.resetFields()}
                        >
                            Undo Changes
                        </Button>
                        <Button
                            type="primary"
                            style={primaryButton({ margin: '0.3em' })}
                            htmlType="submit"
                        >
                            Update
                        </Button>
                    </div>
                </Form>
            </Layout>
        );
    }
}

CategoryConfigurationForm.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
    deviceConfig: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    advanced: PropTypes.bool,
}

CategoryConfigurationForm.defaultProps = {
    deviceConfig: null,
    advanced: false,
}

export default Form.create()(CategoryConfigurationForm);
