
import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";
import { Parser } from "json2csv";
import { DeviceMessageDictionary } from "../util/consts";
const fileDownload = require("js-file-download");
const parser = new Parser();
// DEBUG
window.API = API;

export class DeviceLogStore {
    constructor() {
        this.api = API;
        this.exportHistory = [];

        // search options
        this.device = null;
        this.timerange = [null, null];
        this.deviceCode = null;
        this.selectedEvent = null;

        this.selectedType = null;

        this.messageTypes = [null]
        this.spinning = false;
        this.logHistory = [];
        this.searchText = null;
        makeAutoObservable(this);
    }

    setSearch(text) {
        this.searchText = text;
    }

    UpdateExportHistory = async () => {
        this.exportHistory = [];
        this.selectedEvent = null;
        if (this.timerange[0] === null || this.timerange[1] === null) {
            message.info("Please select a time range");
            return;
        }
        this.spinning = true;
        const response = await this.api.analytics.getDeviceLogs(this.device.serial, this.timerange[0], this.timerange[1]);
        this.exportHistory = response;
        this.spinning = false;
    }

    get displayHistory() {
        let returnValue = this.exportHistory;
        if (this.selectedType) {
            returnValue = returnValue.filter((item) => item.incomingType.includes(this.selectedType));
        }
        //incomingType
        // if (this.searchText) {
        //     returnValue = returnValue.filter(item => {
        //         item.toLower();
        //         return this.searchText.split("").every(internalItem => {
        //             return item.title.toLowerCase().indexOf(internalItem.toLowerCase()) !== -1
        //         })
        //     });
        // }
        if (this.searchText) {
            returnValue = returnValue.filter((item) => item?.serializedPayload?.toLowerCase().includes(this.searchText.toLowerCase()));
        }
        if (this.timerange[0] && this.timerange[1]) {
            returnValue = returnValue.filter((item) => item.epochDate >= (this.timerange[0] * 1000) && item.epochDate <= (this.timerange[1] * 1000));
        }
        return returnValue;
    }

    async updateDevice(device) {
        this.device = device;
        this.deviceCode = device.device_code;
        this.exportHistory = [];
        this.messageTypes = DeviceMessageDictionary[device.device_code];
        const offset = new Date().getTimezoneOffset();
        const response = await this.api.analytics.getHistory(this.device.serial);
        this.logHistory = response;
        this.UpdateExportHistory();
    }

    updateTimeRange(timeRange) {
        this.timerange = timeRange;
    }

    setType(type) {
        this.selectedType = type;
    }

    selectEvent(event) {
        this.selectedEvent = event;
    }

    get ResultSelectedEvent() {
        const outPut = this.selectedEvent;
        let outputJSON = null;
        if (outPut) {
            try {
                outputJSON = JSON.parse(outPut.serializedPayload);
                if (Object.keys(outputJSON).includes("Timestamp") ){
                    outputJSON.Timestamp = new Date(outputJSON.Timestamp * 1000).toLocaleString();
                }

                if (  Object.keys(outputJSON).includes("TimeStamp")) {
                    outputJSON.TimeStamp = new Date(outputJSON.TimeStamp * 1000).toLocaleString();
                }
                if (  Object.keys(outputJSON).includes("_headerRtcDateTime")) {
                    outputJSON._headerRtcDateTime = new Date(outputJSON._headerRtcDateTime * 1000).toLocaleString();
                }if (  Object.keys(outputJSON).includes("EpochDateTime")) {
                    outputJSON.EpochDateTime = new Date(outputJSON.EpochDateTime * 1000).toLocaleString();
                }
            } catch (e) {
                console.log(e);
            }
            return outputJSON;
        }
        return null;
    }


    downloadHistory = async () => {
        const contents = this.displayHistory;
        // this will be downloaded as a csv file
        const csv = parser.parse(contents);
        const date = new Date();
        fileDownload(csv, `DeviceLogs-${date.toISOString()}.csv`);
    }

}

export default createContext(new DeviceLogStore());
