// Style Sheets
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { AnimatePresence } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Router, Switch, useLocation, Link } from "react-router-dom";
import ErrorBoundary from "./components/ErrorHandler";
import InactivityModal from "./components/InactivityModal";
import { io } from "socket.io-client";
// Helpers
import ProtectedRoute from "./components/ProtectedRoute";
import "./index.scss";
import AlertAdminPage from "./pages/AlertAdmin";
import AnalysisExportPage from "./pages/AnalysisExport";
import AnalysisPointsPage from "./pages/AnalysisPoints";
import AnalysisStopsPage from "./pages/AnalysisStops";
import AnalysisTripsPage from "./pages/AnalysisTrips";
import AuditManagementPage from "./pages/AuditManagement";
import UserAuditPage from "./pages/UserAudit";
// import LivePinStore from "./stores/live.pin.store";
// Pages
// NOTE: Not sure if this structure is better or if we should have each component type
// in it's own folder.
// i.e import { LoginPage } from "./pages/authentication/";
// whereas the same folder would contain the Store and the API calls.
import ChangePasswordPage from "./pages/ChangePasswordPage";
import Dashboard from "./pages/Dashboard";
import DeviceManagementPage from "./pages/DeviceManagementPage";
import LoggedInElseWhere from "./pages/ErrorPages/LoggedInElseWhere";
import SessionExpired from "./pages/ErrorPages/SessionExpired";
import NotFoundPage from "./pages/ErrorPages/NotFound";
import ForgotPasswordPage from "./pages/ForgotPassword";
import GeofenceManagementPage from "./pages/GeofenceManagement";
import InvestigationManagementPage from "./pages/InvestigationManagementPage";
import LicenseManagementPage from "./pages/LicenseManagementPage";
import LivePage from "./pages/LivePage";
import LoginPage from "./pages/LoginPage";
import LoginPageExpire from "./pages/LoginPage-expire";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import ProfilePage from "./pages/Profile";
import ProfileCounterPage from "./pages/ProfileCounterPage";
import RequestAccessPage from "./pages/RequestAccessPage";
import SOSPage from "./pages/SOSHistoryPage";
import TargetManagementPage from "./pages/TargetManagementPage";
import TemplateManagementPage from "./pages/TemplateManagementPage";
import UserManagementPage from "./pages/UserManagementPage";
import UserPermissionPage from "./pages/UserPermissionPage";
import WifiManagmentPage from "./pages/WifiManagmentPage";
import DemoSignup from "./pages/DemoSignup";
import AnalysisWifiNetwork from "./pages/AnalysisWifiNetwork";
import WifiDevices from "./pages/WifiDevices";
import MultiTargetDisplay from "./pages/MultiTargetDisplay";
// import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import history from "./services/history";
import NotificationsPage from "./pages/NotificationsPage";
import { Alert, Spin, message, notification } from "antd";
import LivePinStore from "./stores/live.pin.store";
import LiveStore from "./stores/live.store";
import SOSStore from "./stores/sos.store";
import NotificationStore from "./stores/notification.store";
import API from "./services/api";
import getPublicUrl from './utilities/public-url.utility.js';
// import BaseAPI from "./services/api/utilities/request.lib";
import entityManagment from './pages/Entity';
import isvalidStore from "./stores/isvalid.store";
import AnalysisArea from "./pages/AnalysisArea";
import DeviceLog from "./pages/DeviceLog";
import socketHandlerStore from "./stores/socketHandler.store";
import { AlipayCircleOutlined } from "@ant-design/icons";
import { primaryButton } from "./styles";


// HACK: If the token doesn't work, they will get kicked back to the login
// screen when the server tries to verify it and fails. I'm not sure if this
// is the right way to do it or not, but for now, it's a lot better than trying
// to verify the token every time the web app is loaded.
const isAuthenticated = !!localStorage.getItem("token");

const iconNotificationsDictionary = {}

function IndexPage() {
    const [notificationAPI, contextHolder] = notification.useNotification();
    const location = useLocation();
    const sosStore = useContext(SOSStore);
    const livePinStore = useContext(LivePinStore);
    const liveStore = useContext(LiveStore);
    const notificationStore = useContext(NotificationStore);
    const isValidStore = useContext(isvalidStore);
    const socketHandler = useContext(socketHandlerStore);
    useEffect(() => {
        if (isAuthenticated) {
            const token = localStorage.getItem("token");
            const profile = JSON.parse(localStorage.getItem("profile"));
            let socket = {};
            if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                socket = io(localStorage.getItem("serverSocketLocation"), {
                    path: "/socket.io",
                    query: {
                        accessToken: token
                    }
                });
            }
            else {
                socket = io(localStorage.getItem("serverSocketLocation"), {
                    path: "/api/socket.io",
                    query: {
                        accessToken: token
                    }
                });

            }

            socket.on("connect", () => {
                message.success("Connected to the realtime data service");
                socketHandler.setSocketHandler(socket);
            });

            socket.on("NOTIFICATION_SETUP", (data) => {
                const formated = JSON.parse(data);
                socketHandler.receiveNotification(data);
                // notificationAPI.open({
                //     message: formated.notificationTitle,
                //     description: formated.notificationMessage,
                //     duration: 10,
                //     icon: socketHandler.notificationIconDictionary(formated.notificationTitle),
                //     btn: (
                //         <div>
                //             <button
                //                 style={primaryButton({ float: 'left' })}
                //                 onClick={() => socketHandler.markNotificationAsRead(formated)}

                //             >
                //                 Mark as Read
                //             </button>
                //             <Link to="/live">
                //                 <button
                //                     style={primaryButton({ float: 'right', fontColor: 'white', backgroundColor: 'green' })}
                //                     onClick={() => socketHandler.buttonToLivePage(formated)}
                //                 >
                //                     View On live page
                //                 </button>
                //             </Link>
                //         </div>
                //     )
                // })

            });
            socket.on("upgrade", () => {
                console.log("upgrade");
            });

            socket.on("NOTIFICATION", (data) => {
                const formated = JSON.parse(data);
                notificationAPI.open({
                    message: formated.notificationTitle,
                    description: formated.notificationMessage,
                    duration: 10,
                    icon: socketHandler.notificationIconDictionary(formated.notificationTitle),
                    btn: (
                        <button
                            style={primaryButton()}
                            onClick={() => socketHandler.buttonToLivePage()}
                        >
                            View On live page
                        </button>
                    )
                })
                socketHandler.receiveNotification(data);
            });

            socket.on("LIVE_TARGET", (data) => {
                console.log("updated by new socket");
                liveStore.setSocketData(data);
            });

            socket.on("LIVE_PIN", (data) => {
                livePinStore.setSocketData(data);
            })

            socket.on("disconnect", () => {
                message.warning("Disconnected from the realtime data service");
            });
            socket.on("connect_error", (error) => {
                console.log(socket);
                console.log(localStorage.getItem("serverSocketLocation"));
                console.log(error);
                message.error("Connection error to the realtime data service");
            });

            socket.on(`GENERAL_${profile.id}`, async data => {
                console.log("general")
                switch (data.service_name) {
                    case 'LIVE_PIN':
                        livePinStore.setSocketData(data.payload)
                        break;
                    case 'SOS':
                        sosStore.setSocketData(data.payload)
                        break;
                    case 'TARGET_LIVE':
                        console.log("hello");
                        liveStore.setSocketData(data.payload);
                        break;
                    case 'ADMIN_BULLETIN':
                        notificationStore.setSocketData(data.payload);
                        break
                    case 'CLONE_CAPTURE':
                        console.log(data.payload);
                        liveStore.cloneCaptureLive(data.payload);
                        break
                    default:
                        console.log("Default case", data);

                }
            })

        }

        // eslint-disable-next-line
    }, []);
    function fetchConfig() {
        return new Promise((resolve, reject) => {
            fetch(`${getPublicUrl()}/config.json`)
                .then((response) => response.json())
                .then((config) => {
                    resolve(config);
                })
                .catch((exception) => {
                    reject(exception);
                });
        });
    }

    const getEndpointBasedOnEnvironment = async () => {
        // const hostname = window && window.location && window.location.hostname;

        return fetchConfig().then((configTest) => {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                configTest.baseURL = "http://localhost:3000/";
                configTest.serverLocation = "http://localhost:3000/v1/";
                configTest.serverSocketLocation = "http://localhost:3000";
                configTest.messagingServerLocation = "http://localhost:3000";
                configTest.mailListenerDomain = "http://localhost:3000";

                localStorage.setItem("serverLocation", "http://localhost:3000/v1/");
                localStorage.setItem(
                    "messagingServerLocation",
                    configTest.messagingServerLocation
                );
                localStorage.setItem("serverSocketLocation", "http://localhost:3000");
                localStorage.setItem("mailListenerDomain", configTest.mailListenerDomain)
                localStorage.setItem("baseURL", configTest.baseURL)

            } else {
                configTest.serverLocation = window.location.origin + "/api/v1/";
                configTest.serverSocketLocation = window.location.origin;


                localStorage.setItem("serverLocation", window.location.origin + "/api/v1/");
                localStorage.setItem(
                    "messagingServerLocation",
                    configTest.messagingServerLocation
                );
                localStorage.setItem("serverSocketLocation", window.location.origin);
                localStorage.setItem("mailListenerDomain", configTest.mailListenerDomain)
                // config['baseURL'] = configTest.serverLocation;
                //return Promise.resolve(config);
            }
        });
    };
    const [isvalid, setisvalid] = useState(1);
    useEffect(() => {
        getEndpointBasedOnEnvironment();
        // eslint-disable-next-line
        // check if the user is logged in
        isValidStore.checkValidUser().then((res) => {
            if (res) {
                setisvalid(res);
            }
        });
    }, []);


    return (
        <React.Fragment>
            {contextHolder}
            {isvalid === 1 && (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20%",
                    }}
                >
                    <Spin></Spin>
                    <p>Authenticating</p>
                    <Switch>
                        <Route path="/privacypolicy" component={PrivacyPolicyPage} />
                    </Switch>
                </div>
            )}

            {(isvalid === 2 || isvalid === 3) && (
                <AnimatePresence exitBeforeEnter>
                    <ErrorBoundary>
                        <InactivityModal isAuthenticated={isAuthenticated}>
                            <Switch location={location} key={location.pathname}>
                                {/* Unauthenticated Routes */}
                                <ProtectedRoute
                                    auth={!isAuthenticated}
                                    redirect="/"
                                    path="/login"
                                    exact
                                    component={LoginPage}
                                />
                                <ProtectedRoute
                                    auth={!isAuthenticated}
                                    redirect="/"
                                    path="/SignUP"
                                    exact
                                    component={DemoSignup}
                                />
                                <ProtectedRoute
                                    auth={!isAuthenticated}
                                    redirect="/"
                                    path="/forgot-password"
                                    exact
                                    component={ForgotPasswordPage}
                                />
                                <ProtectedRoute
                                    auth={!isAuthenticated}
                                    redirect="/"
                                    path="/password-reset/:token"
                                    exact
                                    component={ChangePasswordPage}
                                />
                                <ProtectedRoute
                                    auth={!isAuthenticated}
                                    redirect="/"
                                    path="/request-access"
                                    exact
                                    component={RequestAccessPage}
                                />

                                {/*  Authenticated Routes */}
                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/DashBoard"
                                    exact
                                    component={Dashboard}
                                />
                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/tools/DeviceLogs"
                                    exact
                                    component={DeviceLog}
                                />

                                {/* Admin */}

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/admin/users"
                                    exact
                                    component={UserManagementPage}
                                />
                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/tools/investigations"
                                    exact
                                    component={InvestigationManagementPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/admin/UserAudit"
                                    exact
                                    component={UserAuditPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/Analysis/Fence"
                                    exact
                                    component={AnalysisArea}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/tools/devices"
                                    exact
                                    component={DeviceManagementPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/admin/licenses"
                                    exact
                                    component={LicenseManagementPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/profile"
                                    exact
                                    component={ProfilePage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/tools/geofences"
                                    component={GeofenceManagementPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/live"
                                    exact
                                    component={LivePage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/"
                                    exact
                                    component={LivePage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/livetest"
                                    exact
                                    component={LivePage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/admin/audit"
                                    exact
                                    component={AuditManagementPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/wifi/wifimang"
                                    exact
                                    component={WifiManagmentPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/wifi/entityManagment"
                                    exact
                                    component={entityManagment}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/wifi/wifidevices"
                                    exact
                                    component={WifiDevices}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/analysis/export"
                                    exact
                                    component={AnalysisExportPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/analysis/trips"
                                    exact
                                    component={AnalysisTripsPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/analysis/stops"
                                    exact
                                    component={AnalysisStopsPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/analysis/multiTargetDisplay"
                                    exact
                                    component={MultiTargetDisplay}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/analysis/points"
                                    component={AnalysisPointsPage}
                                />

                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/wifi/wifiNetwork"
                                    exact
                                    component={AnalysisWifiNetwork}
                                />
                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    redirect="/login"
                                    path="/messages/bulletins"
                                    component={NotificationsPage}
                                />

                                {/* Redirects */}
                                <ProtectedRoute
                                    auth={!isAuthenticated}
                                    redirect="/live"
                                    path="/login"
                                    exact
                                    component={LoginPage}
                                />
                                <ProtectedRoute
                                    auth={!isAuthenticated}
                                    redirect="/live"
                                    path="/login-expire"
                                    exact
                                    component={LoginPageExpire}
                                />
                                <Route
                                    path="/login-detected"
                                    exact
                                    component={LoggedInElseWhere}
                                />
                                <Route
                                    path="/session-expired"
                                    exact
                                    component={SessionExpired}
                                />
                                <Route path="/privacypolicy" component={PrivacyPolicyPage} />

                                {/* Unhandled Route Handler */}
                                <Route component={NotFoundPage} />
                            </Switch>
                        </InactivityModal>
                    </ErrorBoundary>
                </AnimatePresence>
            )}


        </React.Fragment>
    );
}

ReactDOM.render(
    <Router history={history} basename={getPublicUrl()}>
        <IndexPage />
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
