import '@ant-design/compatible/assets/index.css';
import { Button, Radio, Input, Select, Spin, Table, Switch, Tabs, Row, Col, Badge, Form } from "antd";
import { toJS } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import targetStore from '../../stores/target.store';
import AnalysisWifiNetworkTable from '../AnalysisWifiNetworkTable';
import InvestigationStore from '../../stores/investigation.store';
import { primaryButton, contentBox } from '../../styles';
import wifiNetworkStore from "../../stores/wifiNetwork.store";
import {observer} from "mobx-react-lite";
import AnalysisTripsStore from "../../stores/entityDisplay.store";
import AnalysisSiteStore from "../../stores/analysisSuper.store";



function WifiNetworkAnalysisForm(props) {
    const targetstore = useContext(targetStore);
    const investigationstore = useContext(InvestigationStore);
    const investigationSiteStore = useContext(AnalysisSiteStore)
    const entityStore = useContext(AnalysisTripsStore);
    const store = useContext(wifiNetworkStore);
    const [showDropDown, setShowDropDown] = useState(false);


    const [actKey, setActKey] = useState("1");
    const radiochange = (e) => {
        store.setFilter(e.target.value);
    }
    useEffect(() => {
        async function getInvest() {
            await investigationstore.getInvestigationsForUser().then((res) => {
                setShowDropDown(true);
            })
        }
        getInvest();
        // getTarget();
        targetstore.targets = props.targets;

    }, []);

    const hidebtn = (record) => {
        props.tooglehide(record);
    }

    const handleSubmit = (values) => {
        console.log(values);
        store.setInvestigationId(values.InvestigationID);
        store.getWifiNetworks().then(r => {});
    };

    return (
        <div style={contentBox()}>
            <Form
                onFinish={handleSubmit}
                style={{ width: '100%' }}
                initialValues={{InvestigationID: investigationSiteStore.selectedInvestigation}}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '8px', marginBottom: '8px' }}>
                    <div style={{ width: 'calc(100% / 4 * 3)' }} >

                        <Form.Item
                            name="InvestigationID"
                        >
                            <Select
                                showSearch
                                placeholder="Investigation"
                                disabled={showDropDown !== true}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={
                                    toJS(
                                        investigationstore.investigationsForUser.map((ainvest) => {
                                            return {
                                                key: ainvest.id,
                                                value: ainvest.id,
                                                label: ainvest.name
                                            };
                                    }))
                                }
                                onChange={(value) => {
                                    entityStore.setInvestigationId(value);
                                    investigationSiteStore.setInvestigationId(value);
                                }}
                            >
                            </Select>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div style={{width: 'calc(100% / 4)'}}>
                            <Button
                                type={'primary'}
                                htmlType="submit"
                            >Load Investigation</Button>
                        </div>
                    </Form.Item>
                </div>
            </Form>
            <Radio.Group
                onChange={radiochange}
                value={store.filterSetting}
                style={{display: "flex", justifyContent: 'space-between'}}
                buttonStyle={"solid"}
            >
                <Radio.Button value={1} type={"primary"} style={{ width: '100%', textAlign: "center" }}>{"All Associations"}</Radio.Button>
                <Radio.Button value={2} style={{ width: '100%', textAlign: "center" }}>{"Clone"}</Radio.Button>
                <Radio.Button value={3} style={{ width: '100%', textAlign: "center" }}>{"Favourite"}</Radio.Button>
            </Radio.Group>
            <hr />
            <Input
                placeholder="Search an SSID OR MAC Address.."
                value={store.searchText}
                allowClear
                onChange={(event) => {
                    if (event.target.value === "") {
                        store.searchText = null;
                    } else {
                        const query = event.target.value;
                        store.searchText = query;
                    }
                }}
                style={{ margin: "0px 15px 15px 0px" }}
            ></Input>
            <Select
                placeholder="Filter by Entity"
                options={
                    entityStore.entityDropDown()
                }
                onChange={(value) => {
                    store.setEntityFilter(value);
                }}
                mode="multiple"
                allowClear
                style={{
                    width: '100%'
                }}
            >
            </Select>
                <Tabs
                    defaultActiveKey="1"
                    onChange={(key) => {setActKey(key)}}
                    activeKey={actKey}
                >
                    <Tabs.TabPane tab="Access Points" key="1">
                        <AnalysisWifiNetworkTable
                            updateList={() => {store.getWifiNetworks()}}
                            canEdit={true}
                            canDelete={true}
                            sethide={hidebtn}
                            addDragList={store.addDragList}
                            currentlyHiddenAccessPoints={store.hiddenAps}
                            data={store.displayAps}
                            onTargetSelect={(target) => {
                                props.selectedtarget(target);
                            }}
                        />
                    </Tabs.TabPane>
                    {store.displaySelectedAps.length > 0 ? (
                        <Tabs.TabPane
                            tab={(<div>Selected Access Points <Badge style={{ background: "rgb(15, 142, 232)" }} count={store.selectedAps.length} overflowCount={99} /></div>)}
                            key="3"
                        >
                            <row>
                                <Button onClick={() => {
                                    store.clearSelected();
                                }}>
                                    Clear Selected
                                </Button>
                            </row>
                            <AnalysisWifiNetworkTable
                                updateList={() => {store.getWifiNetworks()}}
                                canEdit={true}
                                Fave={true}
                                canDelete={true}
                                sethide={hidebtn}
                                addDragList={store.addDragList}
                                currentlyHiddenAccessPoints={store.hiddenAps}
                                data={store.displaySelectedAps}
                                onTargetSelect={(target) => {
                                    props.selectedtarget(target);
                                }}
                            />
                        </Tabs.TabPane>) : null}
                </Tabs>
        </div>
    );
}

export default observer(WifiNetworkAnalysisForm);
