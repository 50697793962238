import { Badge, Button, Table, message, Spin } from "antd";
import { observer } from "mobx-react-lite";
import StatusDot from "../StatusDot";
import { getBatteryInfo, getGMTDateTime, macToMAC } from "../../util/CommonUtils";
import './DeviceDetails.scss'
import React, { Children, useContext, useEffect, useState } from "react";
import API from "../../services/api";
import DeviceConfigurationStore from "../../stores/device.configuration.store";
import DeviceStore from "../../stores/device.store";
import liveStore from "../../stores/live.store";
import PermissionStore from "../../stores/permission.store";
import WrapperConfigurationForm from "../WrapperConfigurationForm";
import BasicWrapperConfigurationForm from "../BasicWrapperConfigurationForm";
import RecoveryModeModal from "../RecoveryModeDialog"
import { RedbackConfig } from "../../interfaces/redbackWebConfigInterface";
import { greenButton, primaryButton, secondaryButton } from "../../styles";
import { toJS } from "mobx";

// const STATUSES_COLOR = {
//   1: "#f39c12",
//   2: "#27ae60",
//   3: "#c0392b",
// };

const STATUS_LABEL = {
  1: "Pending",
  2: "Accepted",
  3: "Rejected",
};

const DropDownConfig = observer(({Children: thisConfig, item, style, isTestLiveMap, color, toggle}) => {
  const device = item;
  thisConfig = item.config;
  if (thisConfig === undefined) {
    thisConfig = {
      liveMode: null,
      testMode: null,
      liveMode: null,
    };
  }
  const colourHashMap = {
    null: 'white',
    pending: 'orange',
    accepted: 'green',
    prevous: 'grey',
  };

  const applyPreset = async (deviceID, preset) => {
    console.log(deviceID);
    message.loading({ content: 'Applying preset...', key: preset, duration: 0 });
    API.device.postDevicePreset(deviceID, preset)
        .then(() => {
            message.success({ content: 'Preset applied successfully', key: preset });
        })
        .catch((err) => {
            message.error({ content: `Failed to apply preset. [${err.message}]`, key: preset });
        });
  }

  const liveToggle = () => {
    const configDeviceObj = {
      id: device.device_id,
      model: 'REDBACK',
      url_prefix: 'dm',
      serial: device.serial,
    }
    thisConfig = toggle(thisConfig, 'liveMode');
    API.device.updateDeviceConfigurationMode({ id: device.device_id, configuration_mode: 'LIVE' });
    deviceStore.sendRecoveryModeDetails(configDeviceObj, 242405016, device.device_type);
    message.success(
      `Device settings successfully updated for ${device.device_type_name}-${device.serial} device.`
    );
    live.getLiveTargets(true)
    deviceStore.getAllDevices();
  }

  const monitorToggle = () => {
    const configDeviceObj = {
      id: device.device_id,
      model: 'REDBACK',
      url_prefix: 'dm',
      serial: device.serial,
    }
    console.log(device);
    deviceStore.sendRecoveryModeDetails(configDeviceObj, 300, device.device_type);
    thisConfig = toggle(thisConfig, 'monitorMode');
    API.device.updateDeviceConfigurationMode({ id: device.device_id, configuration_mode: 'MONITORING' });
    // applyPreset(device.device_id, 'monitorMode');
    live.getLiveTargets(true)
    setupConfigs();
    deviceStore.getAllDevices();
  }

  const loggingToggle = () => {
    switch (device.configuration_mode) {
      case null: {
        thisConfig = toggle(thisConfig, 'logging');
        API.device.updateDeviceConfigurationMode({ id: device.device_id, configuration_mode: 'LOGGING' });
        applyPreset(device.device_id, 'loggingMode');
        device.configuration_mode = 2;
        break;
      }
      case 2: {
        thisConfig.logging = "prevous";
        // reset to default settings
        device.configuration_mode = null;
        API.device.updateDeviceConfigurationMode({ id: device.device_id, configuration_mode: 'NOTHING' });
        applyPreset(device.device_id, 'default');
        message.success(
          `Device settings successfully updated for ${device.model}-${device.serial} device.`
        );
        break;
      }
      default: {
        thisConfig = toggle(thisConfig, 'logging');
        API.device.updateDeviceConfigurationMode({ id: device.device_id, configuration_mode: 'LOGGING' });
        applyPreset(device.device_id, 'loggingMode');
        if (device.configuration_mode === 1) {
          deviceStore.sendRecoveryModeDetails(device, 300, device.device_type);
        }
        break;
      }
    }
    live.getLiveTargets(true)
    deviceStore.getAllDevices();
    message.success(
      `Device settings successfully updated for ${device.model}-${device.serial} device.`
    );
    setupConfigs();
  }

  const defaultToggle = () => {
    if (!(device.configuration_mode === null)) {
      thisConfig = toggle(thisConfig, 'default');
      const configDeviceObj = {
        id: device.device_id,
        model: 'REDBACK',
        url_prefix: 'dm',
        serial: device.serial,
      }
      API.device.updateDeviceConfigurationMode({ id: device.device_id, configuration_mode: 'NOTHING' });
      applyPreset(device.device_id, 'default');
      if (device.configuration_mode === 1) {
        deviceStore.sendRecoveryModeDetails(configDeviceObj, 300, device.device_type);
      }
      live.getLiveTargets(true)
      setupConfigs();
      deviceStore.getAllDevices();
    } else {
      message.info(`Device is already in default mode.`);
    }
  }

  // this part hurts my soul

  const [isVisible, setIsVisible] = useState(false);
  const [childClick, setChildClick] = useState(false);
  const [configs, setConfigs] = useState();
  const [confirm, setConfirm] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [submitId, setSubmitId] = useState();

  const [spin, SetSpin] = useState(false);
  const device_type = device.device_type;
  const deviceStore = useContext(DeviceStore);
  const live = useContext(liveStore);

  const url_prefix = device.url_prefix; //DM for digital matters


  const onChildClick = () => {
    setChildClick(true);
    setIsVisible(true);
  }

  useEffect(() => {
    if (confirm === true) {

      API.device.sendDeviceSettingsToDevice({
        config_id: submitId,
        device_id: device.id,
        device_type: device.device_type,
        settings: configs.submitAdvanced(submitData, submitId)
      }, device.device_id);
      message.success(
        `Device settings successfully updated for ${device.model}-${device.serial} device.`
      );
    }
  }, [confirm])

  useEffect(() => {
    setupConfigs();

  }, [url_prefix, device_type, childClick]);

  const setupConfigs = () => {

    API.device.getDeviceConfigurationSchema(device.vendor, device_type).then(response => {
      API.device.getAllConfigurationValues(device.id).then(res => {
        setConfigs(new RedbackConfig(response, res))
      })
    });
    SetSpin(true);
  }

  // ============================================================================================
  return (
    <div>
      {spin === true ? (
        <React.Fragment>
          <div
            style={{
              ...style,
              backgroundColor: color ? color : "#fffef0",
              // padding: "3px",

            }
            }
            className={isTestLiveMap ? 'live-map-info-window' : ''}
          >
            <Button
              style={{ backgroundColor: colourHashMap[thisConfig.liveMode], width: "45%", height: "5vh", borderRadius: "5px", margin: "1%", padding: '0.5%', marginLeft: "2%" }}
              onClick={() => {
                liveToggle();
              }}
            >
              Live
            </Button>

            <Button
              style={{ backgroundColor: colourHashMap[thisConfig.monitoring], width: "45%", height: "5vh", borderRadius: "5px", margin: "1%", padding: '0.5%' }}
              onClick={() => { monitorToggle() }}
            >
              Default
            </Button>

          </div>
        </React.Fragment>
      ) : (
        <Spin></Spin>
      )}
    </div>
  );
});

export default DropDownConfig;
