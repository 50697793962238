import { message } from "antd";
import {action, observable, makeAutoObservable, toJS} from "mobx";
import {createContext, useEffect} from "react";
import API from "../services/api";
import {macToMAC} from "../util/CommonUtils";

export class AnalysisSiteStore{
    investigationData = [];
// {
//     name: "",
//     id: "",
//     targets: [
//         {
//             name: "",
//             id: "",
//             colour: "",
//         }
//     ],
//     entity: [
//         {
//             name: "",
//             id: "",
//         }
//     ],
// }
    selectedInvestigation = null;
    constructor() {
        this.api = API;
        makeAutoObservable(this);
    }

    getInvestigationId() {
        return this.selectedInvestigation;
    }

    setInvestigationId(id) {
        this.selectedInvestigation = id;
    }

    getInvestigationData() {
        // api call to get data.
        API.investigation.getInvestigationsForUserNew().then((data) => {
            this.investigationData = data;
        });
    }

    get InvestigationList() {
        const returnList = [];
        this.investigationData.forEach((investigation) => {
            returnList.push({
                label: investigation.name,
                value: investigation.id,
            });
        });
        return returnList;
    }

    getTargetNameById(id, type) {
        let name = "";
        this.investigationData.forEach((investigation) => {
            if (type === "Entity") {
                investigation.entity.forEach((entity) => {
                    if (entity.id === id) {
                        name = entity.name;
                        return name;
                    }
                });
            } else {
                investigation.targets.forEach((target) => {
                    if (target.id === id) {
                        name = target.name;
                        return name;
                    }
                });
            }
        });
        return name;
    }

    getTargetsByInvestigationId(id) {
        const returnList = [];
        this.investigationData.forEach((investigation) => {
            if (investigation.id === id) {
                investigation.targets.forEach((target) => {
                    returnList.push(['Target', target.id]);
                });
            }
        });
        return returnList;
    }

    getColourByTargetId(id) {
        if (!id || id.length === 0) {
            return "#000000";
        }
        if (id[0] === "Entity") {
            return "#000000";
        }
        let index = this.investigationData.findIndex((investigation) => { return investigation.id === this.selectedInvestigation});
        if (index === -1) {
            return "#000000";
        }
        let targetIndex = this.investigationData[index].targets.findIndex((target) => { return target.id === id[1]});
        if (targetIndex === -1) {
            return "#000000";
        }
        return this.investigationData[index].targets[targetIndex].colour;
    }

    get TargetsForInvestigation() {
        if (!this.selectedInvestigation) {
            return [];
        }
        const targetList = [];
        const entityList = [];
        this.investigationData.forEach((investigation) => {
            if (investigation.id === this.selectedInvestigation) {
                investigation.entity.forEach((entity) => {
                    entityList.push({
                        label: entity.name,
                        value: entity.id,
                    });
                })
                investigation.targets.forEach((target) => {
                    targetList.push({
                        label: target.name,
                        value: target.id,
                    });
                });
            }
        });
        let returnList = [];
        if (targetList.length > 0) {
            returnList.push({
                label: 'Target',
                value: 'Target',
                children: targetList
            });
        }
        if (entityList.length > 0) {
            returnList.push({
                label: 'Entity',
                value: 'Entity',
                children: entityList
            });
        }
        return returnList;
    }

}

export default createContext(new AnalysisSiteStore());
