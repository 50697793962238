import { Button, Divider, message, Table } from "antd";
import React, { useEffect, useState, useContext } from "react";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import "./InvestigationTable.scss";
import { convertSpeed, customSorter, formatDate, macToMAC } from "../../util/CommonUtils";
import EntityMap from "../EntityMap";
import EntityEditForm from "../EntityEditForm";
import API from "../../services/api";
import DeviceEditForm from "../DeviceEditForm";
import { primaryButton, secondaryButton, contentBox } from '../../styles'
import { toJS } from "mobx";
import EntityAddForm from "../../components/EntityAddForm";
import investigationStore from '../../stores/investigation.store';
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import WiFiDeviceCapturesTable from "../WiFiDeviceCapturesTable";
import EntityDisplayStore from "../../stores/entityDisplay.store";
import analysisSuperStore from "../../stores/analysisSuper.store";
const { Column } = Table;

export default function (props) {
    const investigationstore = useContext(investigationStore);
    const EntityStore = useContext(EntityDisplayStore);
    const siteStore = useContext(analysisSuperStore);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});
    const [update, setupdate] = useState(false);
    const [entityList, setEntityList] = useState([]);

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [mode1, setMode1] = useState("add");
    const [existingData1, setExistingData1] = useState({});
    const [selected, setSelected] = useState(null);

    const [
        {
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            entitiesForInvestigation,
        },
        setSelectedInvestigation,
        setSelectedTarget
    ] = useInvestigationForUser();

    async function updateEntity(values) {
        await API.entity.updateEntity(values.id, values.nickname, values.notes).then((res) => {
            if (res.status === 200) {
                message.info("Updated");
                window.location.reload(false);
            }
        })
    }
    async function entityCreate(values) {
        await API.entity.createEntity(values.InvestigationID, values.nickname, values.notes).then((res) => {
            if (res.status === 200) {
            }
        })
    }

    const upDateEntlist = () => {
        API.entity.GetAllEntities(selectedInvestigation).then((res) => {
            setEntityList(res);
        })
    }

    const _onEditRecord = (record) => {
        props.onEditRecord(record);
    };

    let devices = props.data

    devices.map((device) => {
        device.last_seenNTZ = device.last_seen?.split("GMT")[0]
    })

    let time = new Date(0);

    const editbtn = (record) => {
        EntityStore.setInvestigationId(siteStore.getInvestigationId())
        EntityStore.setDeviceAndOpen(record.id);
    }

    const entityadd = () => {
        setMode1("add");
        setExistingData1();
        setIsModalOpen1(true);
    }

    async function updateDevice(values) {
        await API.wifiDevice.update(values.id, values.nickname, values.entityName).then((res) => {
            if (res.status === 200) {
                message.info("Updated");
                //window.location.reload(false);
            }
        })
    }
    if (devices) {
        return (
            <div>
                <div>
                    <Table
                        expandRowByClick={true}
                        dataSource={devices}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div>
                                    <WiFiDeviceCapturesTable
                                        setApLatitude={props.setApLatitude}
                                        setApLongitude={props.setApLongitude}
                                        setApTrip={props.setApTrip}
                                        setClones={props.setClones}
                                        setSelected={setSelected}
                                        selected={selected}
                                        data={record}
                                        InvestigationID={props.InvestigationID}
                                        setMainEvent={props.setMainEvent}
                                        setIsLoading={props.setIsLoading}
                                    />
                                </div>
                            ),
                        }}
                        size="small"
                        rowKey={(record) => { return (`${record.id}${record.apID}${record.id + record.apID}`) }}
                        // pagination={{position: ["topRight"]}}
                        scroll={{ y: "45vh" }}
                    >
                        {/* <Column
                            title="id"
                            dataIndex="id"
                            key="id"
                        /> */}
                        <Column
                            title="Entity"
                            dataIndex="entity_alias"
                            key="entity_alias"
                        />
                        <Column
                            title="Alias"
                            dataIndex="alias"
                            key="alias"
                        />
                        <Column
                            title="Device Mac Address"
                            dataIndex="device"
                            key="device"
                            render={item => macToMAC(item)}
                        />
                        {/* <Column
                            title="metadata"
                            dataIndex="metadata"
                            key="metadata"
                        /> */}

                        <Column
                            title="SSID"
                            dataIndex="accessPoint"
                            key="accessPoint"
                        />
                        <Column
                            title="Last Seen"
                            dataIndex="last_seen"
                            key="Last_seen"
                            render={item => new Date(item).toLocaleString()}
                        />
                        {/* <Column
                            title="Last Location"
                            dataIndex="location"
                            key="location"
                        /> */}
                        <Column
                            title=""
                            key="action"
                            render={(text, record) => (
                                <span>
                                    <span>
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                editbtn(record);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    </span>
                                </span>
                            )}
                        />
                    </Table>
                </div>
                <DeviceEditForm
                    opened={isModalOpen}
                    mode={mode}
                    device={existingData || null}
                    targets={props.entities}
                    entityadd={entityadd}
                    onSubmit={(data) => {
                        const { values, mode } = data;
                        updateDevice(values);
                        if (mode === "edit") {

                            setIsModalOpen(false);
                            setExistingData({});
                            setupdate(true);

                        } else {
                            console.log(`not edit module view form: ${JSON.stringify(values)}`);
                        }
                    }}
                    onCancel={() => {
                        setIsModalOpen(false);
                        setExistingData({});
                    }}
                />
                <EntityAddForm
                    selectedInvestigation={selectedInvestigation}
                    investigations={toJS(
                        investigationstore.investigationsForUser)}
                    opened={isModalOpen1}
                    mode={mode1}
                    passthrough={[]}
                    data={existingData1 || null}
                    onSubmit={(data) => {
                        const { values, mode } = data;
                        upDateEntlist();
                        entityCreate(values);
                        if (mode === "add") {
                            setIsModalOpen1(false);
                            setExistingData1({});
                        }
                    }}
                    onCancel={() => {
                        setIsModalOpen1(false);
                        setExistingData1({});
                    }}
                />
            </div>
        );
    }
    return (null);
}
