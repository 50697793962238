import {observer} from "mobx-react-lite";
import {Input, Modal, Divider, Button, message, Tooltip, Form, Row, Descriptions, Col, Spin} from "antd";
import {Fragment, useContext, useEffect, useState} from "react";
import entityDisplayStore from "../../stores/entityDisplay.store";
import {macToMAC} from "../../util/CommonUtils";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    Polyline,
    InfoBox,
} from "@react-google-maps/api";
import getPublicURL from "../../utilities/public-url.utility";
import AnalysisSiteStore from "../../stores/analysisSuper.store";
import {ClockCircleOutlined, HistoryOutlined, QuestionCircleOutlined, WifiOutlined} from "@ant-design/icons";
import WifiStore from "../../stores/wifi.store";
import axios from "axios";
function EntityModal() {
    const store = useContext(entityDisplayStore);
    const siteStore = useContext(AnalysisSiteStore);
    const wifiStore = useContext(WifiStore);
    const [map, setMap] = useState(null);
    const google = window.google;
    store.setInvestigationId(siteStore.getInvestigationId());
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }
    const success = (pos) => {
        const crd = pos.coords;
        if (store.wifiDeviceObj) {
            map.panTo({lat: store.wifiDeviceObj.apLat, lng: store.wifiDeviceObj.apLon});
        }
    }
    useEffect(() => {
        if (store.wifiDeviceObj) {
            map.panTo({lat: store.wifiDeviceObj.apLat, lng: store.wifiDeviceObj.apLon});
        }
    }, [store.wifiDeviceObj]);
    const fail = (error) => {
        if (error.code === 1) {
            message.error("Failed to load map position. We do not have location privileges.");
        }
        console.log(`Error: ${error.code}`);
    }
    useEffect(() => {
        if (map) {
            navigator.geolocation.getCurrentPosition(success, fail, options);
        }
    }, [map]);

    const [form] = Form.useForm();

    useEffect(() => {
        if (store.wifiDeviceObj) {
            form.setFieldsValue({WiFiAlias: store.wifiDeviceObj?.alias});
        }
    }, [store.wifiDeviceObj]);

    let bounds = new window.google.maps.LatLngBounds();

    useEffect(() => {
        if (!bounds) return
        store.wifiDeviceObj?.apList.forEach(point => {
            bounds.extend({
                lat: point.apLat,
                lng: point.apLon
            })
            console.log("beep boop")
        });
        if (map) map.fitBounds(bounds, window.innerWidth * 0.05);
    },[
        store.wifiDeviceObj?.apList,
    ]);

    return (
        <div>
            <Modal
                title="WiFi Connection View"
                visible={store.entityModalOpen}
                okText={"Save"}
                onOk={() => {store.saveWifiConnectionChanges()}}
                onCancel={() => store.onCloseEntityModal()}
                width={1200}
            >
                <Row >
                <Col span={11} style={{width: '47%', marginRight: '2%', boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.4)', borderRadius: '10px', padding: '5px'}}>
                    {store.doesEntityExist ? (<div>
                        <div style={{
                            backgroundColor: '#85a5ff',
                            padding: '2%',
                            borderRadius: '10px',
                            marginBottom: '5%',
                            color: 'white'
                        }}>
                            <h4>{`${store.entityName}`}</h4>
                        </div>
                    </div>) : (<div>

                        <Button
                            type="primary"
                            style={{width: '100%', borderRadius: '10px'}}
                            onClick={() => {
                                store.setEntityCreationModalOpen(true);
                            }}
                        >
                            Create Or Assign An Entity
                        </Button>
                        <Divider/>
                    </div>)}
                    <div style={{
                        backgroundColor: '#85a5ff',
                        padding: '2%',
                        borderRadius: '10px',
                        marginBottom: '5%',
                        color: 'white'
                    }}>

                        <div
                            style={{float: 'right'}}
                        >
                            <Tooltip title={"View History"} >
                                <Button shape={"circle"} icon={<HistoryOutlined/>}
                                        onClick={() => {
                                            wifiStore.setDeviceIdAndOpen(store.wifiDeviceId)

                                        }}
                                ></Button>
                            </Tooltip>
                            <Tooltip title={"Mac Address Look up"}>
                                <Button
                                    shape={"circle"}
                                    icon={wifiStore.macRequestLoading ? <Spin spinning={wifiStore.macRequestLoading}/> : <QuestionCircleOutlined/> }
                                    onClick={() => {
                                        console.log(macToMAC(store.wifiDeviceObj?.mac_address))
                                        wifiStore.macAddressLookup(macToMAC(store.wifiDeviceObj?.mac_address)).then(res => {
                                            console.log(res)
                                            if (res === "") {
                                                message.warning("Nothing Found for Mac Look up")
                                            } else {
                                                form.setFieldsValue({WiFiAlias: res})
                                            }
                                        })
                                    }}
                                ></Button>
                            </Tooltip>
                        </div>
                        <h4 style={{color: '#333130'}}>Device MacAddress: </h4>
                        <h4 style={{
                            color: 'white',
                            paddingLeft: '15%'
                        }}>{macToMAC(store.wifiDeviceObj?.mac_address)}</h4>
                        <Form
                            name={"wifiDeviceForm"}
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                        >
                            <Form.Item name="WiFiAlias" >
                                <Input
                                    addonBefore={"Name Association"}
                                    placeHolder={"Name Wifi Device Connection"}
                                    style={{
                                        borderRadius: '5%',
                                        width: '100%'
                                    }}
                                    onChange={value => {
                                        store.setWifiDeviceAlias(value.target.value);
                                    }}
                                />
                            </Form.Item>
                        </Form>
                        <h4 style={{color: '#333130'}}>Associated Access Points:</h4>
                        <div style={{maxHeight: '20vh', overflow: 'scroll', width: '100%', overflowX: 'hidden'}}>
                            {store.wifiDeviceObj?.apList.map((ap) => {
                                return (
                                    <div style={{paddingLeft: '5%'}}>
                                        <Row><p style={{fontWeight: 'bold', color: '#333130'}}>SSID: </p>
                                            <p>{ap.apAlias}</p></Row>
                                        <Row><p style={{fontWeight: 'bold', color: '#333130'}}>MacAddress: </p>
                                            <p>{macToMAC(ap.apMac)}</p></Row>
                                        <Divider style={{marginBottom: '2%', marginTop: '2%'}}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Col>
                <Col span={12} style={{width: '47%', height: '100%' , boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.4)', borderRadius: '10px', padding: '5px'}}>
                    <div style={{
                        width: "100%",
                        height: "51vh",
                        marginBottom: '5%',
                        borderRadius: '10px',
                    }}>
                        <h5>Access Point Map</h5>
                        <GoogleMap
                            defaultCenter={{
                                lat: -27.4515181,
                                lng: 153.0537391,
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            mapContainerStyle={{
                                width: "100%",
                                height: "100%",
                                borderRadius: '10px',
                                boxShadow: '1px 1px 10px 1px grey;'
                            }}
                            zoom={15}
                            onLoad={map => setMap(map)}
                        >
                            {store.wifiDeviceObj && store.wifiDeviceObj.apList && store.wifiDeviceObj.apList.map((ap) => {
                                return (
                                    <Marker
                                        position={{lat: ap.apLat, lng: ap.apLon}}
                                        icon={{
                                            url: `${getPublicURL()}/icons8-wifi-64.png`,
                                            scaledSize: new google.maps.Size(35, 35),
                                            anchor: new google.maps.Point(17.5, 17.5)
                                        }}
                                    >
                                        <InfoWindow
                                            position={{lat: ap.apLat, lng: ap.apLon}}
                                        >
                                            <p>{ap.apAlias}</p>
                                        </InfoWindow>
                                    </Marker>
                                )
                            })}
                        </GoogleMap>
                    </div>
                </Col>
            </Row>
            </Modal>
        </div>
    );
}

export default observer(EntityModal);