import { message } from "antd";
import FuzzySearch from "fuzzy-search";
import { action, computed, observable, makeAutoObservable, toJS } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

export class InvestigationStore {
    constructor() {
        this.investigations = [];
        this.closedInvestigations = [];
        this.investigationsForUser = [];
        this.ongoingInvestigations = [];
        this.searchQuery = "";
        this.onlyMyinvestigations = false;
        this.api = API;
        makeAutoObservable(this);
    }

    get results() {
        let investigationsToDisplay = this.investigations;
        if (this.onlyMyinvestigations) {
            investigationsToDisplay = this.investigationsForUser;
        }
        if (this.searchQuery) {
            /*
            const searcher = new FuzzySearch(
                this.investigationsToDisplay,
                ["name", "description"],
                {
                    caseSensitive: false
                }
            );
                */
            let returnValue = investigationsToDisplay;
            if (this.searchText) {
                returnValue = returnValue.filter((item) => item?.name?.toLowerCase().includes(this.searchText.toLowerCase()));
            }

            return returnValue;

            //return searcher.search(this.searchQuery.trim());
        } else {
            return investigationsToDisplay;
        }
    }

    get ongoingResults() {
        if (this.searchQuery) {
            // const searcher = new FuzzySearch(
            //     this.ongoingInvestigations,
            //     ["name", "description"],
            //     {
            //         caseSensitive: false
            //     }
            // );
            this.ongoingInvestigation();
            let list = this.ongoingInvestigations;
            return list.filter((item) => item.name.includes(this.searchQuery))
            // return searcher.search(this.searchQuery.trim());
        } else {
            this.ongoingInvestigation();
            return this.ongoingInvestigations;
        }
    }

    showOnlyMyInvestigations() {
        this.onlyMyinvestigations = !this.onlyMyinvestigations;
    }

    async getInvestigations() {
        this.ongoingInvestigations = [];
        const response = await this.api.investigation.getAllInvestigations();
        this.investigations = response;
        this.ongoingInvestigation();
    }

    async getInvestigationsForUser() {
        this.investigationsForUser = [];
        const response = await this.api.investigation.getInvestigationsForUser();
        this.investigationsForUser = response;
    }

    setSearchQuery(query) {
        this.searchQuery = query;
    }

    ongoingInvestigation() {
        for (let i = 0; i < this.investigations.length; i++) {
            let currentDate = new Date();
            let myDate = new Date(this.investigations[i].end_datetime * 1000);
            if (currentDate < myDate && !this.containsObject(this.investigations[i], this.ongoingInvestigations)) {

                const start_date_filtered = new Date(Number(this.investigations[i].start_datetime) * 1000).toLocaleString()
                this.investigations[i].start_datetime_filtered = start_date_filtered

                const end_date_filtered = new Date(Number(this.investigations[i].end_datetime) * 1000).toLocaleString()
                this.investigations[i].end_datetime_filtered = end_date_filtered

                const createdAt_filtered = new Date(Number(this.investigations[i].created_at) * 1000).toLocaleString()
                this.investigations[i].createdAt_filtered = createdAt_filtered

                this.ongoingInvestigations.push(this.investigations[i]);
            }
        }
    }

    containsObject(obj, list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === obj.id) {
                return true;
            }
        }
        return false;
    }

    async createInvestigation({
        name,
        description,
        start_datetime,
        end_datetime,
        users,
        colour,
        speed_unit,
        caseOfficer
    }) {
        try {
            await this.api.investigation.createInvestigation({
                name,
                description,
                start_datetime,
                end_datetime,
                users,
                colour,
                speed_unit,
                caseOfficer
            });
            message.success(`Investigation  has been created.`);
            this.getInvestigations();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async updateInvestigation({
        id,
        name,
        description,
        start_datetime,
        end_datetime,
        users,
        colour,
        speed_unit,
        caseOfficer
    }) {
        try {
            await this.api.investigation.updateInvestigation({
                id,
                name,
                description,
                start_datetime,
                end_datetime,
                users,
                colour, speed_unit, caseOfficer
            });
            message.success("Investigation has been updated");
            this.getInvestigations();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async deleteInvestigation(record) {
        try {
            await this.api.investigation.deleteInvestigation(record.id);
            message.success(`Investigation '${record.name}' has been deleted.`);
            this.getInvestigations();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }
}

export const store = new InvestigationStore();
export default createContext(store);
