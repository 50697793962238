import { SettingOutlined, ApartmentOutlined, BranchesOutlined, DeleteFilled, ForkOutlined, ShareAltOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { Button, Divider, message, Table, Tooltip, Tabs, Modal } from "antd";
import { PlusOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useContext } from "react";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import "./InvestigationTable.scss";
import { customSorter, formatDate, macToMAC } from "../../util/CommonUtils";
import EntityMap from "../EntityMap";
import EntityEditForm from "../EntityEditForm";
import API from "../../services/api";
import DeviceEditForm from "../DeviceEditForm";
import WatchlistSharingForm from "../watchlistSharingForm";
import DeviceUnlinkForm from '../DeviceUnlinkForm';
import { secondaryButton } from "../../styles";
const { Column } = Table;
const { TabPane } = Tabs;
import entitypageStore from '../../stores/entitypage.store';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import EntityDisplayStore from "../../stores/entityDisplay.store";


function entityTables(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});
    const [update, setupdate] = useState(false);

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [mode1, setMode1] = useState("add");
    const [existingData1, setExistingData1] = useState({});
    const [update1, setupdate1] = useState(false);
    const [entityList, setEntityList] = useState([]);
    const [device, setdevice] = useState({});
    const [history, setHistory] = useState([]);
    const [UnlinkData, setUnlinkData] = useState(0);
    const [UninkModal, setUnlinkModal] = useState(false);
    const [watchListOpen, setWatchList] = useState(false);
    const [latlng, setLatlng] = useState({});
    const [selectedCap, setSelectedCap] = useState({});
    const [trip, setTrip] = useState([]);
    const [type, setType] = useState("");
    const [isModalDelOpen, setIsModalDelOpen] = useState(false);

    const EntityStore = useContext(entitypageStore);

    async function updateEntity(values) {
        await API.entity.updateEntity(values.id, values.nickname, values.notes).then((res) => {
            if (res.status === 200) {
                message.info("Updated");
                entity.notes = values.notes;
            }
        })
    }

    async function deleteEntity(id) {
        await API.entity.deleteEntity(id).then((res) => {
            if (res.status === 200) {
                message.info("Deleted");
            }
        })
    }

    async function unLinkDevice(device_id, alias) {
        await API.wifiDevice.update(device_id, alias, 0).then((res) => {
            if (res.status === 200) {
                message.info("Updated");
            }
        })
    }

    function  getHistory(expanded, record) {

        if (expanded) {
            API.CloneCapture.historyDevice(record.id).then((res) => {
                // date format
                let len = res.length;
                for (let i = 0; i < len; i += 1) {
                    let date = new Date(0);
                    date.setUTCSeconds(res[i].timestamp)
                    res[i].timeStamp = date.toString();
                }
                console.log(JSON.stringify(res.length));
                console.log(record.apMAC);
                res = res.filter((item) => {
                    return item.apMAC === record.apMAC
                });
                console.log(JSON.stringify(res.length));
                setHistory(prev => (prev.concat(res)));
            })
        }
        else {
            // row closed remove histories
            setHistory(history.filter(item => item.wifi_device_id !== record.id));
        }
    }

    const expandedRowRender = (stuff, record) => {
        let storage = [];
        let len = record.length;
        for (let i = 0; i < len; i += 1) {
            if (record[i].wifi_device_id === stuff.id) {
                storage.push(record[i]);
            }
        }
        // sort storage
        storage.reverse();
        const columns = [
            { title: "Event type", dataIndex: "capture_type", key: "capture_type" },
            { title: "Time (Local)",
                dataIndex: "timeStamp",
                key: "timeStamp",
                render: (text) => {
                    return <p>{text.split("GMT")[0]}</p>
                } },
            { title: "RSSI", dataIndex: "rssi", key: "rssi" },
            { title: "Tracking Device", dataIndex: "name", key: "name" }
        ]

        return <Table
            columns={columns}
            dataSource={storage}
            pagination={true}
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        setSelectedCap(record.id);
                        EntityStore.setLatLng({ lat: record.latitude, lng: record.longitude });
                        EntityStore.setTrip([]);
                        EntityStore.setType(record.capture_type);
                        API.analysis.getWIfiDeviceLastTrip(props.investigation, record.target_id, record.timestamp).then((res) => {
                            EntityStore.setTrip(res.trip_data);
                        })
                    },
                    style: (function () {
                        if (selectedCap === record.id) {
                            return { backgroundColor: "#f8f4f4" };
                        } else {
                            return {};
                        }
                    })(),
                }
            }}
        />;
    }
    const Combainedcolumns = [
        { title: "Event type", dataIndex: "capture_type", key: "capture_type" },
        {
            title: "Time (Local)",
            dataIndex: "timeStamp",
            key: "timeStamp",
            render: (text) => {
                return <p>{text.split("GMT")[0]}</p>
            }
        },
        { title: "RSSI", dataIndex: "rssi", key: "rssi" },
        { title: "Tracking Device", dataIndex: "name", key: "name" }
    ]
    const editbtn = () => {
        setMode("edit");
        setExistingData(entity);
        setIsModalOpen(true);
    }
    const storeForEntityDisplay = useContext(EntityDisplayStore);
    const editbtn1 = (record) => {
        storeForEntityDisplay.setDeviceAndOpen(record.id);
    }


    const unlinkBtn = (device_id) => {
        setUnlinkModal(true);
        setUnlinkData(device_id);
    }

    async function updateDevice(values) {
        await API.wifiDevice.update(values.id, values.nickname, values.name).then((res) => {
            if (res.status === 200) {
                message.info("Updated");
                // window.location.reload(false);
            }
        })
    }

    const watchlistopen = () => {
        setWatchList(true)
    }
    const makeParagraphs = (text) => {
        if (text) {
            return text.split("\n").map((item, i) => {
                return <p key={i}>{item}</p>;
            });
        }
    };


    const _onEditRecord = (record) => {
        props.onEditRecord(record);
    };
    let entity = props.data
    console.log(entity);
    if (entity) {
        return (
            <div>
                <DeviceUnlinkForm
                    opened={UninkModal}

                    data={UnlinkData || null}
                    onSubmit={(data) => {
                        unLinkDevice(data.values.id, data.values.alias);
                        setUnlinkModal(false);
                        setUnlinkData(0);
                    }}
                    onCancel={() => {
                        setUnlinkModal(false);
                    }}
                >
                </DeviceUnlinkForm>
                <EntityEditForm
                    opened={isModalOpen}
                    mode={mode}
                    entity={entity}
                    data={existingData || null}
                    onSubmit={(data) => {
                        const { values, mode } = data;
                        updateEntity(values);
                        if (mode === "edit") {
                            setIsModalOpen(false);
                            setExistingData({});
                            setupdate(true);

                        }
                    }}
                    onCancel={() => {
                        setIsModalOpen(false);
                        setExistingData({});
                    }}
                ></EntityEditForm>
                <Modal
                    title="Delete Entity"
                    visible={isModalDelOpen}
                    onOk={() => {
                        setIsModalDelOpen(false);
                        deleteEntity(entity.id);
                        props.removeEntityFromArray(entity.id);
                    }}
                    onCancel={() => {
                        setIsModalDelOpen(false);
                    }}
                >

                </Modal>

                <DeviceEditForm
                    opened={isModalOpen1}
                    mode={mode1}
                    device={existingData1 || null}
                    targets={entityList}
                    onSubmit={(data) => {
                        const { values, mode } = data;
                        updateDevice(values);
                        if (mode === "edit") {

                            setIsModalOpen1(false);
                            setExistingData1({});
                            setupdate1(true);

                        }
                    }}
                    onCancel={() => {
                        setIsModalOpen1(false);
                        setExistingData1({});
                    }}
                ></DeviceEditForm>
                <WatchlistSharingForm
                    opened={watchListOpen}
                    mode={mode1}
                    data={[]}
                    targets={[]}
                    onSubmit={(data) => {
                        const { values, mode } = data;

                        if (mode === "edit") {

                            setIsModalOpen1(false);
                            setExistingData1({});
                            setupdate1(true);

                        }
                    }}
                    onCancel={() => {
                        setWatchList(false);
                        setIsModalOpen1(false);
                        setExistingData1({});
                    }}
                ></WatchlistSharingForm>
                <h3>Entity Details</h3>
                <div
                    style={{
                        marginLeft: "1vw",
                    }}
                >
                    {makeParagraphs(entity.notes)}
                </div>

                <Tabs
                    tabBarExtraContent={
                        <div>
                             <Tooltip title="Combined View">
                                <Button
                                    style={secondaryButton()}
                                    icon={<DeploymentUnitOutlined />}
                                    onClick={() => {
                                        EntityStore.setCombainedView();
                                    }}
                                />
                            </Tooltip>
                            <Divider type="vertical" />
                            <Tooltip title="Edit">
                                <Button
                                    style={secondaryButton()}
                                    icon={<SettingOutlined />}
                                    onClick={() => {
                                        editbtn();
                                    }}
                                />
                            </Tooltip>
                            <Divider type="vertical" />
                            <Tooltip title="Watchlist Sharing">
                                <Button
                                    style={secondaryButton()}
                                    icon={<ShareAltOutlined />}
                                    onClick={() => {
                                        watchlistopen();
                                    }}
                                />
                            </Tooltip>
                            <Divider type="vertical" />
                            <Tooltip title="Delete Entity">
                                <Button
                                    style={{ borderRadius: "5px" }}
                                    type="danger"
                                    icon={<DeleteFilled />}
                                    onClick={() => {
                                        setIsModalDelOpen(true);
                                    }}
                                />
                            </Tooltip>

                        </div>

                    }
                >
                    <TabPane tab="WiFi Devices" key="1">
                        <div>
                            {/*Connected devices*/}
                            {EntityStore.combainedView ?
                                <div>
                                    <Table
                                        style={{ height: "100%" }}
                                        columns={Combainedcolumns}
                                        dataSource={toJS(EntityStore.combainedTrips)}
                                        pagination={true}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => {
                                                    setSelectedCap(record.id);
                                                    EntityStore.setLatLng({ lat: record.latitude, lng: record.longitude });
                                                    EntityStore.setTrip([]);
                                                    EntityStore.setType(record.capture_type);
                                                    API.analysis.getWIfiDeviceLastTrip(props.investigation, record.target_id, record.timestamp).then((res) => {
                                                        EntityStore.setTrip(res.trip_data);
                                                    })
                                                },
                                                style: (function () {
                                                    if (selectedCap === record.id) {
                                                        return { backgroundColor: "#f8f4f4" };
                                                    } else {
                                                        return {};
                                                    }
                                                })(),
                                            }
                                        }}
                                    />

                                </div> :
                                <Table
                                    dataSource={entity.Devices}
                                    rowKey={record => `${record.id}_${record.apMAC}`}
                                    expandable={{
                                        onExpand: (expanded, record) => getHistory(expanded, record),
                                        expandedRowRender: record => expandedRowRender(record, history),
                                        rowExpandable: record => record.alias,
                                        defaultExpandAllRows: false,
                                        // TODO: do the fix to limit only one device open at a time see below
                                        // const onExpand = (expanded, record) => {
                                        //     const expandedRows = [];
                                        //     const expandedCon = [];
                                        //
                                        //     if (expanded) {
                                        //         expandedRows.push(record.id)
                                        //         expandedCon.push(record)
                                        //     }
                                        //     setExpandedData(expandedRows)
                                        //     setExpandedContent(expandedCon)
                                        // }

                                    }}
                                    pagination={{ pageSize: 10 }}
                                    expandIcon={({ expanded, onExpand, record }) =>
                                        expanded ? (
                                            <UpOutlined onClick={e => onExpand(record, e)} />
                                        ) : (
                                            <RightOutlined onClick={e => onExpand(record, e)} />
                                        )}
                                >
                                    <Column
                                        title="Alias"
                                        dataIndex="alias"
                                        key="alias"
                                    />
                                    <Column
                                        title="mac_address"
                                        dataIndex="mac_address"
                                        key="mac_address"
                                        render={item => macToMAC(item)}
                                    />
                                    <Column
                                        title="apAlias"
                                        dataIndex="apAlias"
                                        key="apAlias"
                                        render={(text, record) => {
                                            return (
                                                <div>
                                                    <Tooltip title={macToMAC(record.apMAC)}>
                                                        <p>{text}</p>
                                                    </Tooltip>
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column
                                        title=""
                                        key="action"
                                        render={(text, record) => (
                                            <span>
                                                <span>
                                                    <Button
                                                        type="link"
                                                        onClick={() => {
                                                            record.type = "device"
                                                            editbtn1(record);
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            unlinkBtn(record)
                                                        }}
                                                        type="link"
                                                    >
                                                        Unlink
                                                    </Button>
                                                </span>
                                            </span>
                                        )}
                                    />
                                </Table>
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="Access Points" key="2">
                        <div>

                            <Table

                                dataSource={entity.APs}
                                size="middle"
                                rowKey="id"
                                pagination={true}
                            >
                                <Column
                                    title="SSID"
                                    dataIndex="alias"
                                    key="alias"
                                />
                                <Column
                                    title="Mac Address"
                                    dataIndex="mac_address"
                                    key="mac_address"
                                    render={mac => macToMAC(mac)}
                                />
                                <Column
                                    title="Location"
                                    dataIndex="worded"
                                    key="worded"
                                />
                            </Table>
                        </div>
                    </TabPane>
                    {/* <TabPane tab="Associations" key="3" visible="false">
                    </TabPane> */}

                </Tabs>

            </div>
        );
    } else {
        return null
    }

}

export default observer(entityTables);