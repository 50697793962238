// export function customSorter(a, b) {
//   // Handle them based on the type
//   // a || 0 or a || "" means to replace with the value after || if a is null
//   if (typeof a === "number") {
//     return (a || 0) > (b || 0) ? 1 : -1;
//   } else {
//     return (a || "").localeCompare(b || "") ? 1 : -1;
//   }
// }

import momentTimeZone from "moment-timezone";
import moment from "moment";
import cityTimezones from 'city-timezones';
import React from "react";
import getPublicURL from "../utilities/public-url.utility";

export const has = Object.prototype.hasOwnProperty;

export function customSorter(a, b) {
    // check if the values have numbers
    if (Number.isInteger(a)) {
        return (a || 0) - (b || 0);
    } else {
        return (a || "").localeCompare(b || "");
    }
}

export function convertSpeed(speed_unit, speed) {
    if (speed_unit === 'MPH') {
        speed = speed && speed > 0 ? Number((speed / 1.60934).toFixed(2)) : 0
    }
    return `${speed} ${speed_unit}`;
}

export function getTimeZones() {
    const cityMapping = cityTimezones.cityMapping
    const cityTimeZones = [...new Set(cityMapping.map(x => x.timezone))]
    const timeZones = cityTimeZones
        .map(t => {
            return {
                label: `(GMT${momentTimeZone.tz(t).format("Z")}) ${t}`,
                value: t,
                offset: momentTimeZone.tz(t).format("Z")
            }
        });

    return timeZones.sort((a, b) => {
        let [ahh, amm] = a.label.split("GMT")[1].split(")")[0].split(":");
        let [bhh, bmm] = b.label.split("GMT")[1].split(")")[0].split(":");
        return (+ahh * 60 + amm) - (+bhh * 60 + bmm)
    });
}

export function macToMAC(deviceAddress) {
    if (deviceAddress) {
        if (typeof (deviceAddress) === 'number') {
            deviceAddress = deviceAddress.toString('16');
        }
        let len = deviceAddress.length;
        while (deviceAddress.length < 12) {
            deviceAddress = '0' + deviceAddress;
        }
        len = deviceAddress.length
        let tx2 = "";
        for (let i = 0; i < len; i += 2) {
            tx2 = tx2 + deviceAddress[i];
            if (deviceAddress[i + 1] === undefined) {
                tx2 = tx2 + 0
            } else {
                tx2 = tx2 + deviceAddress[i + 1];
            }
            if (i + 1 <= len) {
                tx2 = tx2 + ':';
            }
        }
        tx2 = tx2.toUpperCase();
        if (tx2[tx2.length - 1] === ':') {
            tx2 = tx2.slice(0, tx2.length - 1);
        }
        return tx2;
    }
    return null;
}

export function getQuickSearchTimeRange(value) {
    let timeRange = []
    const currentDate = moment()

    switch (value) {
        case 1:
            timeRange.push(moment.utc().subtract(12, "hours"))
            break;
        case 2:
            timeRange.push(moment.utc().subtract(24, "hours"))
            break;
        case 3:
            timeRange.push(moment.utc().subtract(48, "hours"))
            break;
        case 4:
            timeRange.push(moment.utc().subtract(72, "hours"))
            break;
        default:
            timeRange.push(moment.utc().subtract(7, "d"))
            break;
    }

    timeRange.push(currentDate);
    return timeRange;
}

export function getTargetIcon(colour) {
    return [
        '<?xml version="1.0"?>',
        '<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" >',
        `<circle cx="12.5" cy="12.5" r="10.5625" fill="white" stroke="${colour ? colour : "#2980b9"}" stroke-width="4"/>`,
        '<circle cx="12.5" cy="12.5" r="7" fill="black" />',
        '</svg>'
    ].join('\n');
}

export function setDefaultAnalysisInvestigation(investigation) {
    localStorage.setItem("defaultAnalysisInvestigation", JSON.stringify({
        id: JSON.parse(localStorage.getItem("profile")).id,
        investigation: investigation,
    }))
}

export function getDefaultAnalysisInvestigation() {
    let user = JSON.parse(localStorage.getItem("profile"));
    let defaultInvestigation = JSON.parse(localStorage.getItem("defaultAnalysisInvestigation"));
    if (user && defaultInvestigation) {
        if (user.id === defaultInvestigation.id) {
            return defaultInvestigation.investigation
        }
    }
    return null;
}

export function setDefaultAnalysisTarget(investigation) {
    localStorage.setItem("defaultAnalysisTarget", JSON.stringify({
        id: JSON.parse(localStorage.getItem("profile")).id,
        target: investigation,
    }))
}

export function getDefaultAnalysisTarget() {
    let user = JSON.parse(localStorage.getItem("profile"));
    let defaultTarget = JSON.parse(localStorage.getItem("defaultAnalysisTarget"));
    if (user && defaultTarget) {
        if (user.id === defaultTarget.id) {
            return defaultTarget.target
        }
    }
    return null;
}

export function getGMTDateTime(timestamp, isGMT) {
    const timeZone = momentTimeZone.tz.guess();
    const offset = momentTimeZone.tz(timeZone).format("Z");
    if (localStorage.getItem("utcTime") === "true") {
        let profile = JSON.parse(localStorage.getItem("profile"));
        return moment(timestamp * 1000).utc().format(`${profile.date_format} HH:mm:ss`)
    }
    return `${formatDate(timestamp, "HH:mm:ss")} ${isGMT ? `GMT${offset}` : ''}`
}

export function formatDate(timestamp, timeFormat) {
    let profile = JSON.parse(localStorage.getItem("profile"));
    return moment(Number(timestamp) * 1000).format(`${profile.date_format} ${timeFormat ? timeFormat : ''}`);
}

export function getUTCDateTime(timestamp) {
    let profile = JSON.parse(localStorage.getItem("profile"));
    return `${moment(timestamp * 1000).utc().format(`${profile.date_format} HH:mm:ss`)} GMT`
}

export function getBatteryInfo(batteryInfo) {
    if (batteryInfo === null) return "N/A";
    if (typeof (batteryInfo) === 'string' && batteryInfo.includes('%')) {
        console.log(batteryInfo);
        console.log("here init");
        let voltage = batteryInfo.replace('%', '').trim();
        let Cleanvoltage = parseFloat(voltage.replace('&&', '').trim());
        if (Cleanvoltage > 70) {
            return (
            <span>
                    {Cleanvoltage} %
                    <img src={`${getPublicURL()}/battery-success.svg`} style={{
                        marginTop: "-2px",
                        height: "1.5em",
                        marginLeft: '5px'
                    }} alt="" />
                </span>
            );
        } else if (Cleanvoltage >= 40) {
            return (
                <span>
                    {Cleanvoltage} %
                    <img src={`${getPublicURL()}/battery-warning.svg`} style={{
                        marginTop: "-2px",
                        height: "1.5em",
                        marginLeft: '5px'
                    }} alt="" />
                </span>
            );
        } else {
            return (
                <span>
                    {Cleanvoltage} %
                    <img src={`${getPublicURL()}/battery-error.svg`} style={{
                        marginTop: "-2px",
                        height: "1.5em",
                        marginLeft: '5px'
                    }} alt="" />
                </span>
            );
        }
    }
    let batteryObj = batteryInfo && !isNaN(batteryInfo)  ? JSON.parse(batteryInfo) : batteryInfo;
    if (batteryObj && typeof batteryObj !== 'object') {
        //const minimumThreshold = 3400;
        if (3.7 < batteryInfo) {
            return (
                <span>
                    {batteryInfo} V
                    <img src={`${getPublicURL()}/battery-success.svg`} style={{
                        marginTop: "-2px",
                        height: "1.5em",
                        marginLeft: '5px'
                    }} alt="" />
                </span>
            );
        } else if (3.7 >= batteryInfo && 3.5 < batteryInfo) {
            return (
                <span>
                    {batteryInfo} V
                    <img src={`${getPublicURL()}/battery-warning.svg`} style={{
                        marginTop: "-2px",
                        height: "1.5em",
                        marginLeft: '5px'
                    }} alt="" />
                </span>
            );
        } else {
            return (
                <span>
                    {batteryInfo} V
                    <img src={`${getPublicURL()}/battery-error.svg`} style={{
                        marginTop: "-2px",
                        height: "1.5em",
                        marginLeft: '5px'
                    }} alt="" />
                </span>
            );
        }
    } else {
        return "N/A";
    }
}

export function mapDefault() {
    try {
        if (JSON.parse(localStorage.getItem("savedMap"))) {
            return (JSON.parse(localStorage.getItem("savedMap")))
        } else if (JSON.parse(localStorage.getItem("profile")).city_location) {
            return (JSON.parse(JSON.parse(localStorage.getItem("profile")).city_location))
        } else {
            return ({ "lat": -35.2802, "lng": 149.1310 })
        }
    } catch (e) {
        return ({ "lat": -35.2802, "lng": 149.1310 })
    }
}
