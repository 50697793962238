import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import AnalysisTripsStore from "../../stores/analysisTrips.store";
import {
    Badge,
    Button,
    Card,
    Col,
    Collapse,
    Descriptions,
    Divider,
    message,
    Popover,
    Progress,
    Row,
    Space,
    Tooltip
} from "antd";
import getPublicURL from "../../utilities/public-url.utility";
import {
    EnvironmentFilled,
    FacebookOutlined, HistoryOutlined,
    InfoCircleOutlined,
    UserOutlined,
    UserSwitchOutlined,
    WifiOutlined
} from "@ant-design/icons";
import EntityDisplayStore from "../../stores/entityDisplay.store";
import WifiStore from "../../stores/wifi.store";
import {Tabs} from "antd/es";

function formatWifiEntityTitle(entity) {
    const tripsStore = useContext(AnalysisTripsStore)
    const EntityStore = useContext(EntityDisplayStore)
    const wifiStore = useContext(WifiStore)
    const onclick = (record, type) => {
        EntityStore.setDeviceAndOpen(record.id);
    };
    return (
        <div>
            <Card
                style={{width: '100%', marginBottom: '1%', boxShadow: '5px 5px 5px rgba(0,0,0,0.7)', borderRadius: '5px'}}
                bodyStyle={{height: '100%', paddingTop: "10%", padding: '0', paddingLeft: '2%', paddingRight: '10%'}}
            >
                <Row>
                    <Col span={8} >
                        <Row style={{width: '100%', justifyContent: 'center', marginTop: '20%',  marginBottom: "10%"}}>
                            {entity.TimeStamp > tripsStore.TripHalfWayTimeStamp ?
                                (<Tooltip title={"End of trip"}>
                                    <img src={`${getPublicURL()}/end_of_trip.png`}
                                         style={{width: '2vw', marginRight: '5%'}}
                                    /></Tooltip>) :
                                (<Tooltip title={"Start of Trip"}><img
                                    src={`${getPublicURL()}/start_of_trip.svg`}
                                    style={{width: '1.5vw', marginRight: '5%'}}
                                /></Tooltip>)
                            }
                            {tripsStore.isWifiCloned(entity.capture_type) ? (
                                <Popover title={"Capture"}
                                         content={<div>
                                             <p style={{color: 'grey'}}>Huntsman Active Capture of the device</p>
                                             {entity.hitCount.map(capture => {
                                                 return <p>{capture.capture.split('WIFI_FRAME_FUNCTION_')[1]} - {capture.rssi}</p>
                                             })}
                                         </div>}>
                                    <img
                                        src={`${getPublicURL()}/CloneCapture100px.png`}
                                        style={{width: '2vw', height: '2vw'}}
                                    />
                                </Popover>
                            ) : (
                                <Popover title={"Scan"}
                                         content={<div>
                                             <p style={{color: 'grey'}}>Huntsman Passive Scan of available networks</p>
                                             {entity.hitCount.map(capture => {
                                                 console.log(capture)
                                                 return <p>{capture.capture.split('WIFI_FRAME_FUNCTION_')[1]} - {capture.rssi}</p>
                                             })}
                                </div>}>
                                    <img
                                        src={`${getPublicURL()}/wifiMini.png`}
                                        style={{width: '2vw', height: '2vw'}}
                                    />
                                </Popover>
                            )}
                            <Badge
                                count={entity.hitCount.length}
                                // size="small"
                                color="blue"
                                offset={[2, 0]}
                            > </Badge>
                        </Row>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Tooltip title={`rssi: -${entity.rssi} dbm`} placement={"left"}>
                                <Progress steps={4} percent={100 - entity.rssi} showInfo={false} success={{percent: 25}}/>
                            </Tooltip>
                        </Row>

                    </Col>
                    <Col span={13} style={{marginRight: '5%'}}>

                        <Row><p style={{color: 'grey'}}>SSID: </p><p>{`${entity.apAlias}`}</p></Row>
                        <Row><p style={{color: 'grey'}}>Association Name: </p><p>{`${entity.Alias}`}</p></Row>
                        <Row><p style={{color: 'grey'}}>Mac Address: </p><p>{`${entity.MAC}`}</p></Row>
                    </Col>
                    <Col span={1} style={{marginRight: '1%', flex: 'auto', marginLeft: 'auto', marginTop: '5%'}}>
                        <Tooltip title={"Pan To Capture"}>
                            <Button icon={<EnvironmentFilled/>} type={"primary"} onClick={() => {
                                if (tripsStore.mapRef === null || tripsStore.mapRef === undefined) {
                                    message.error("Map not loaded yet");
                                    return;
                                }
                                tripsStore.mapRef.panTo({lat: entity.latitude, lng: entity.longitude});
                                tripsStore.mapRef.setZoom(20);
                            }}
                                    style={{marginBottom: '7%'}}
                            />
                        </Tooltip>
                        <Tooltip title={"View WiFi Connection"} placement={"left"}>
                            <Button icon={<WifiOutlined/>} type={"primary"} onClick={() => {
                                onclick(entity, 'wifi');
                            }}
                                    style={{marginBottom: '7%'}}>
                            </Button>
                        </Tooltip>
                        <Tooltip title={"View History"} placement={'bottom'}>
                            <Button type={"primary"} icon={<HistoryOutlined/>}
                                    onClick={() => {
                                        wifiStore.setDeviceIdAndOpen(entity.id)
                                    }}
                            ></Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

function WifiActivityTable(data) {
    const tripsStore = useContext(AnalysisTripsStore)
    const EntityStore = useContext(EntityDisplayStore)
    const entity = data.entity;
    const wifiStore = useContext(WifiStore)
    const map = data.map;

    const onclick = (record, type) => {
        EntityStore.setDeviceAndOpen(record.id);
    };
    return (
        // todo: this uses vw here
        <div style={{zIndex: 9999999, width: '100%', minWidth: '10vw', padding: '1%'}}>
            <h6>Entities Seen</h6>
            <Collapse
                style={{width: '100%', backgroundColor: '#f5f5f5', borderRadius: '5px', border: '#4096ff solid 1px'}}
                accordion={true}
                expandIcon={() => <UserSwitchOutlined style={{fontSize: '120%'}}/>}>
                {tripsStore.EntitiesExist(entity.key) ? tripsStore.CreateEntityTree(entity.key).map((entity, index) => {
                    return (
                        <Collapse.Panel
                            header={entity.title}
                            key={entity.key}>
                                {entity.children.map((child, index) => {
                                    return formatWifiEntityTitle(child)
                                })}
                        </Collapse.Panel>
                    )
                })
                :
                    <p>No Entities</p>
                }
            </Collapse>

            <Divider style={{marginBottom: '1%', marginTop: '1%'}}/>
            <Row>
                <h6 style={{marginRight: '1%'}}>Unassigned Captures</h6>

                <Popover placement={'bottom'} content={
                    <div>
                        <h5>This shows captures in the trip</h5>
                        <p>Access points are listed with connected devices</p>
                        <p>Access Points are ordered so associated Access Points are towards the top</p>
                    </div>
                }>
                    <h6> <InfoCircleOutlined style={{color: "#707070"}}/> </h6>
                </Popover>
            </Row>

            {tripsStore.NotEntitiesExist ? tripsStore.getWifiInfo(entity.key).map((ap) => {
                return (
                    <div style={{marginBottom: '5%'}}>
                        <Tooltip title={"Access Point"} >
                            <h6 style={{textAlign: 'center'}}>{ap.Alias}</h6>
                        </Tooltip>
                        {ap.DeviceList.map((device, index) => {
                            return (
                                <div>
                                    <Card
                                        style={{width: '100%', marginBottom: '1%', boxShadow: '5px 5px 5px rgba(0,0,0,0.7)', borderRadius: '5px', paddingTop: '10px', paddingBottom: '10px'}}
                                        bodyStyle={{paddingTop: "0", padding: '0', paddingLeft: '2%', paddingRight: '10%'}}
                                    >
                                        <Row>
                                            <Col span={8}>
                                                <Row style={{width: '100%', justifyContent: 'center', marginTop: '30%', marginBottom: "10%"}}>

                                                    {device.TimeStamp > tripsStore.TripHalfWayTimeStamp ?
                                                        (<Tooltip title={"End of trip"}>
                                                            <img src={`${getPublicURL()}/end_of_trip.png`}
                                                                 style={{width: '2vw'}}
                                                            /></Tooltip>) :
                                                        (<Tooltip title={"Start of Trip"}><img
                                                            src={`${getPublicURL()}/start_of_trip.svg`}
                                                            style={{width: '1.5vw'}}
                                                        /></Tooltip>)
                                                    }
                                                    <Divider type={'vertical'} />
                                                    {tripsStore.isWifiCloned(device.capture_type) ? (
                                                        <Popover title={"Capture"} content={
                                                            <div>
                                                                <p style={{color: 'grey'}}>Huntsman Active Capture of the device</p>
                                                                {device.hitCount.map(capture => {
                                                                        return <p>{capture.capture.split('WIFI_FRAME_FUNCTION_')[1]} - {capture.rssi}</p>
                                                                    })
                                                                }
                                                            </div>}>
                                                            <img
                                                                src={`${getPublicURL()}/CloneCapture100px.png`}
                                                                style={{width: '2vw', height: '2vw'}}
                                                            />
                                                        </Popover>
                                                    ) : (
                                                        <Popover title={"Scan"}
                                                                 content={<div>
                                                                     <p style={{color: 'grey'}}>Huntsman Passive Scan of available networks</p>
                                                                     {device.hitCount.map(capture => {
                                                                         return <p>{capture.capture.split('WIFI_FRAME_FUNCTION_')[1]} - {capture.rssi}</p>
                                                                     })}
                                                        </div>}>
                                                            <img
                                                                src={`${getPublicURL()}/wifiMini.png`}
                                                                style={{width: '2vw'}}
                                                            />
                                                        </Popover>
                                                    )}
                                                    <Badge
                                                        count={device.hitCount.length}
                                                        // size="small"
                                                        color="blue"
                                                        offset={[2, -10]}
                                                    > </Badge>
                                                </Row>
                                                <Row style={{width: '100%', justifyContent: 'center'}}>
                                                    <Tooltip title={`rssi: -${device.rssi} dbm`} placement={"left"}>
                                                        <Progress steps={4} percent={100 - device.rssi} showInfo={false} success={{percent: 25}}/>
                                                    </Tooltip>
                                                </Row>

                                            </Col>

                                            <Col span={13} style={{marginRight: '5%'}}>
                                                <p>{`Device: ${index + 1}`}</p>
                                                <Row><p style={{color: 'grey'}}>SSID: </p><p>{`${ap.Alias}`}</p></Row>
                                                <Row><p style={{color: 'grey'}}>Alias: </p><p>{`${device.Alias}`}</p>
                                                </Row>
                                                <Row><p style={{color: 'grey'}}>Mac Address: </p>
                                                    <p>{`${device.MAC}`}</p></Row>
                                            </Col>
                                            <Col span={1} style={{marginRight: '1%', flex: 'auto', marginLeft: 'auto'}}>
                                                <Tooltip title={"Pan To Capture"}>
                                                    <Button icon={<EnvironmentFilled/>} type={"primary"}
                                                            onClick={() => {
                                                                if (tripsStore.mapRef === null || tripsStore.mapRef === undefined) {
                                                                    message.error("Map not loaded yet");
                                                                    return;
                                                                }
                                                                tripsStore.mapRef.panTo({
                                                                    lat: device.latitude,
                                                                    lng: device.longitude
                                                                });
                                                            }}
                                                            style={{marginBottom: '7%'}}
                                                    />
                                                </Tooltip>
                                                <Tooltip title={"View WiFi Connection"} placement={"left"}>
                                                    <Button icon={<WifiOutlined/>} type={"primary"} onClick={() => {
                                                        onclick(device, 'wifi');
                                                    }}
                                                            style={{marginBottom: '7%'}}
                                                    >
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title={"View History"} placement={'bottom'}>

                                                        <Button type={"primary"} icon={<HistoryOutlined/>}
                                                                onClick={() => {
                                                                    wifiStore.setDeviceIdAndOpen(device.id)
                                                                }}
                                                        ></Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            )
                        })}

                    </div>
                )
            }) : <p>No Unassociated Captures</p>}
        </div>
    )
}

export default observer(WifiActivityTable);