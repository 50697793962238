import {Spin, Button, Alert, message, Col, Row, Drawer} from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import investigationStore from '../../stores/investigation.store';
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import API from "../../services/api";
import WifiMapTrips from '../../components/AnalysisMapTrips';
import Layout from "../AppLayout";
import NickNameStore from "../../stores/NickName.store";
import { primaryButton } from "../../styles";
import {
    getDefaultAnalysisInvestigation,
    getDefaultAnalysisTarget, setDefaultAnalysisInvestigation,
    setDefaultAnalysisTarget
} from "../../util/CommonUtils";
import TripTable from "../../components/AnalysisTripsPanel";
import permissionStore from "../../stores/permission.store";
import EntityMap from "../../components/EntityMap";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import AnalysisExportsStore from "../../stores/analysisExport.store";

import DownloadOptionsModal from "../../components/DownloadOptionsModal";

import WifiActivityTable from "../../components/AnalysisMapTrips/WifiActivityTable";
import AnalysisTripsStore from "../../stores/analysisTrips.store";

import WifiDrawer from "./WifiDrawer";

const AnalysisTripsPage = (props) => {
    const permissionstore = useContext(permissionStore)
    const tripsStore = useContext(AnalysisTripsStore);
    const [trips, setTrips] = useState([]);
    const [currentlySelectedTrip, setCurrentlySelectedTrip] = useState(null);
    const [selectedTrips, setSelectedTrips] = useState([]);
    const [selectedSubPoint, setSelectedSubPoint] = useState({});
    const [selectedSubPointFromMap, setSelectedSubPointFromMap] = useState({});
    const [dateRange, setDateRange] = useState([]);
    const [cloneCapture, setCloneCapture] = useState(null);
    const [bluetoothCapture, setBluetoothCapture] = useState(null);

    // Alias form and customization
    const [update, setupdate] = useState(false);
    const [entityList, setEntityList] = useState([]);
    const [Eswitch, setEswitch] = useState(true);

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [visible, setVisible] = useState(true);
    const [formVisible, setFormVisible] = useState(true);
    const [margin, setMargin] = useState('33%');
    const [MarkerPlots, setMarkerPlots] = useState([]);

    const [entity, setEntity] = useState(false);

    const [buttonOpacity, setButtonOpacity] = useState('1.0');
    const [buttonMargin, setButtonMargin] = useState('1%')


    const [
        {
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            entitiesForInvestigation,
        },
        setSelectedInvestigation,
        setSelectedTarget
    ] = useInvestigationForUser();

    const showDrawer = () => {
        setButtonOpacity('1.0');
        setButtonMargin('1%');
        setVisible(true);
        setMargin("33%");

    };
    const onClose = () => {
        setVisible(false);
        setMargin('0px');
        setButtonMargin("0px");
    };

    const setHoverBut = () => {
        setButtonOpacity('0.7');
    }

    const unSetHover = () => {
        setButtonOpacity('1.0');
    }

    const upDateEntlist = () => {
        API.entity.GetAllEntities(selectedInvestigation).then((res) => {
            setEntityList(res);
        })
    }

    useEffect(() => {
        if (update == true) {
            setupdate(false);
            if (selectedTrips.length > 0) {
                let start = selectedTrips[0].device_utc_date_time;
                let stop = selectedTrips[0].stopped_date_time;
                let id = selectedInvestigation;
                API.CloneCapture.ClonedTripCheck(id, start, stop).then((response) => {
                    setCloneCapture(response);
                });

                API.bluetooth.getCaptures(id, start, stop).then((response) => {
                    setBluetoothCapture(response);
                })

            }
        }
    }, [update]);
    // <><><><><><><><>

    // const [selectedDateRange, setSelectedDateRange] = useState(null);
    const investigationstore = useContext(investigationStore);
    const [deselectTrip, setDeselectTrip] = useState(false);

    const [showStopsMap, setShowStopsMap] = useState(false);

    useEffect(() => {

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }

        return () => clearTimeout(timer);
    }, []);

    const [multipleTripsVisibilityOnMap, setMultipleTripsVisibility] = useState(
        false
    );

    const downloadOptionsModalFunction = () => {
        setIsModalOpen2(true);
    }
    const exportStore = useContext(AnalysisExportsStore);


    const dlOptions = ['JSON', 'CSV', 'KML', 'PDF']

    const downloadPoints = async (options) => {
        if (exportStore.isSet()) {
            exportStore.verifyAndLog(dlOptions[options]);
        } else {
            message.error('Please select a time range and a target to export points');
        }
    };

    const [speedUnitString, setSpeedUnitString] = useState("");


    const PermCheck = !permissionstore.can("Entity.view")
    return (
        <Layout showNavigation hideMargins>
            {showStopsMap === false && (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20%",
                    }}
                >
                    <Spin></Spin>
                </div>
            )}
            <div>
                <div style={{
                    position: "fixed",
                    zIndex: 2,
                    width: "100%"
                }}>
                    < Button type="primary" onClick={visible ? onClose : showDrawer}
                             style={primaryButton({
                                 position: "absolute",
                                 top: "40vh",
                                 zIndex: 2,
                                 height: "15vh",
                                 marginTop: "0%",
                                 left: buttonMargin,
                                 marginLeft: "2px",
                                 borderLeftColor: "#0066CC",
                                 marginBottom: "2px",
                                 opacity: buttonOpacity,
                             })}
                             onMouseEnter={() => {
                                 setHoverBut()
                             }}
                             onMouseLeave={() => {
                                 unSetHover()
                             }}
                    >
                        {
                            visible
                                ?
                                <ArrowLeftOutlined
                                    width={25}
                                    height={25}
                                />
                                :
                                <ArrowRightOutlined
                                    width={25}
                                    height={25}
                                />
                        }
                    </Button>
                </div>
                <WifiDrawer />
                <TripTable
                    downloadModelOpenFunction={() => {
                        downloadOptionsModalFunction()
                    }}
                    onExpand={(visibleItems) => {
                        if (visibleItems.length > 0) {
                            setFormVisible(true);
                        } else {
                            setFormVisible(false);
                        }
                    }}
                    visible={visible}
                    showDrawer={showDrawer}
                    onClose={onClose}
                    trips={trips}
                    currentlySelectedTrip={currentlySelectedTrip}
                    selectedTrips={selectedTrips}
                    onTripClick={(item) => {
                        setCurrentlySelectedTrip(item);

                        if (multipleTripsVisibilityOnMap === true) {
                            let categories = new Set(selectedTrips);
                            categories.add(item);
                            // need to sort this before pushing
                            let tripArray = Array.from(categories);
                            tripArray.sort((a, b) => a.device_utc_date_time - b.device_utc_date_time);
                            setSelectedTrips(tripArray);
                        } else {
                            setSelectedTrips(item)
                            // setSelectedTrip(item);
                        }
                    }}
                    onTripSubPointClick={(subPoint) => {
                        setSelectedSubPoint(subPoint);
                    }}
                    selectedSubPointFromMap={selectedSubPointFromMap}
                    onDeselectTrip={(record) => {
                        // setDeselectTrip(true);
                        const tripsWithCurrentTripRemoved = selectedTrips.filter(
                            (currentTrip) => currentTrip.id !== record.id
                        );

                        setSelectedTrips(tripsWithCurrentTripRemoved);
                        if (currentlySelectedTrip) {
                            if (currentlySelectedTrip.id === record.id) {
                                setCurrentlySelectedTrip(null);
                            }
                        }
                        setDeselectTrip(true);
                    }}
                    speedUnitString={speedUnitString}
                    onUTCChange={() => {
                        if (selectedTarget && selectedInvestigation) {
                            if (Eswitch === true) {
                                API.CloneCapture.getDeviceLogDateRange({
                                    investigation_id: selectedInvestigation.id,
                                    entity_id: entity
                                }).then((records) => {
                                    setDateRange(records);
                                });
                            } else {
                                API.analysis.getDeviceLogDateRange({
                                    investigation_id: selectedInvestigation,
                                    target_id: selectedTarget
                                }).then((records) => {
                                    setDateRange(records);
                                });
                            }
                        }
                    }}
                >
                    <DownloadOptionsModal
                        opened={isModalOpen2}
                        onSubmit={(data) => {
                            downloadPoints(data);
                            setIsModalOpen2(false);
                        }}
                        onCancel={() => {
                            setIsModalOpen2(false);
                        }}
                        isPoints={true}
                    ></DownloadOptionsModal>
                </TripTable>
            </div>
            <div>
                <WifiMapTrips
                    panBy={[-300, 0]}
                    data={selectedTrips}
                    style={{
                        width: "100%",
                        height: "100vh",
                    }}
                    bluetoothCapture={bluetoothCapture}
                    selectedTarget={{lat: 0, lng: 0}}
                    cloned_captured_data={cloneCapture}
                    MarkerPlots={MarkerPlots}
                    Eswitch={Eswitch}
                    selectedTargetID={selectedTarget}
                    tripsFormVisible={formVisible}
                    speedUnitString={speedUnitString}
                />
            </div>
        </Layout>
    );
};

export default observer(AnalysisTripsPage);
