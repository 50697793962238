import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const createInvestigation = async ({
    name,
    description,
    start_datetime,
    end_datetime,
    users,
    colour,
    speed_unit,
    caseOfficer
}) => {
    try {
        const response = await HTTP.post("/investigation", {
            name,
            description,
            start_datetime: start_datetime.toString(),
            users,
            colour,
            speed_unit,
            caseOfficer
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        console.log('response', response.data)
        const data = response.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateInvestigation = async ({
    id,
    name,
    description,
    start_datetime,
    users,
    colour,
    speed_unit,
    caseOfficer
}) => {
    try {
        if (caseOfficer === []) {
            caseOfficer = null
        }
        const response = await HTTP.put(`/investigation/${id}`, {
            name,
            description,
            start_datetime: start_datetime.toString(),
            users: users,
            colour,
            speed_unit,
            caseOfficer
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data =
            response.data.data.investigation[
            response.data.data.investigation.length - 1
            ];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteInvestigation = async id => {
    try {
        const response = await HTTP.delete(`/investigation/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllInvestigations = async () => {
    try {
        const response = await HTTP.get(`/investigation`);
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.investigation;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getInvestigationsForUser = async () => {
    try {
        const response = await HTTP.get(`/investigation/user`);
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.investigation;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getInvestigationsForUserNew = async () => {
    try {
        const response = await HTTP.get(`/investigation/userNew`);
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.investigation;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};


const getAllUsersForSelectedInvestigation = async (investigation_id) => {
    if (!investigation_id) {
        return Promise.resolve([]);
    }
    try {
        const response = await HTTP.get(`/investigation/${investigation_id}`);
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.investigation;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}


const createWarrant = async ({
    investigationId,
    warrantId,
    warrantExpiry,
    warrantNotes,
}) => {
    try {
        const response = await HTTP.post("/investigation/warrant", {
            investigationId,
            warrantId,
            warrantExpiry,
            warrantNotes,
        });
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateState = async (investigation_id, state) => {
    console.log('update state', investigation_id, state);
    try {
        const response = await HTTP.post(`/investigation/${investigation_id}/state/`, { state: state });
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.investigation;
        console.log('data', data);

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateWarrant = async ({
    id,
    investigationId,
    warrantId,
    warrantExpiry,
    warrantNotes,
}) => {
    console.log(id, investigationId, warrantId, warrantExpiry, warrantNotes);
    try {
        const response = await HTTP.put(`/investigation/warrant/${id}`, {
            id,
            investigationId,
            warrantId,
            warrantExpiry,
            warrantNotes,
        });
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateUsers = async (investigation_id, users) => {
    try {
        const response = await HTTP.post(`/investigation/${investigation_id}/users/`, { user_id: users });
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.investigation;

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteWarrant = async (id, investigation) => {
    try {
        const response = await HTTP.delete(`/investigation/warrant/${id}/${investigation}`);
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getWarrantsForInvestigation = async (investigation_id) => {
    try {
        const response = await HTTP.get(`/investigation/warrant/${investigation_id}`);
        const data = response.data.warrant;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    createInvestigation,
    deleteInvestigation,
    getAllInvestigations,
    updateInvestigation,
    getInvestigationsForUser,
    getAllUsersForSelectedInvestigation,
    createWarrant,
    updateWarrant,
    deleteWarrant,
    getWarrantsForInvestigation,
    updateState,
    updateUsers,
    getInvestigationsForUserNew,
};
