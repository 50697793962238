import {observer} from "mobx-react-lite";
import {Drawer} from "antd";
import WifiActivityTable from "../../components/AnalysisMapTrips/WifiActivityTable";
import React, {useContext} from "react";
import analysisTripsStore from "../../stores/analysisTrips.store";

import './trips.css'

function WifiDrawer() {
    const tripsStore = useContext(analysisTripsStore);
    return (
        <div>
            { tripsStore.entityDrawVisible &&
                <Drawer
                    title={"Trip Activity"}
                    visible={tripsStore.entityDrawVisible}
                    onClose={() => tripsStore.setEntityDrawVisible(false)}
                    width={'25%'}
                    placement="right"
                    mask={false}
                    headerStyle={{background: '#f8f4f4'}}
                >
                    <WifiActivityTable entity={{key: 'endFlag'}} map={null}/>
                </Drawer>
            }
        </div>
    )
}


export default observer(WifiDrawer)