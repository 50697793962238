import { Badge, Table, Tree } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import StatusDot from "../StatusDot";
import { getBatteryInfo, getGMTDateTime, macToMAC, convertSpeed } from "../../util/CommonUtils";
import { CarOutlined, DeploymentUnitOutlined, TeamOutlined, LogoutOutlined, DeleteOutlined, MessageOutlined, UploadOutlined, UnlockOutlined, UserOutlined, BookOutlined } from "@ant-design/icons";
import './DeviceDetails.scss';
import getPublicURL from "../../utilities/public-url.utility";
import liveStore from "../../stores/live.store";
import {BatteryFull, CarFrontFill, ClockFill, GeoAltFill, HddRackFill, HouseFill} from "react-bootstrap-icons";
import AddressDisplay from "../AnalysisTripsPanel/AddressDisplay";

// eslint-disable-next-line
const STATUS_LABEL = {
    1: "Pending",
    2: "Accepted",
    3: "Rejected",
};

// eslint-disable-next-line
function isDateBeforeToday(date) {
    return new Date(date) < new Date(new Date());
}

function getSSID(metadata) {
    console.log(metadata);
    let ssid = JSON.parse(metadata);
    return (ssid.ssid);
}

function convertToTime(timestamp) {
    //const utcMode = localStorage.getItem("utcTime");

    let date = new Date(0);
    date.setUTCSeconds(timestamp);
    let hours = date.getHours();
    let minute = date.getMinutes();
    return (`${hours}:${minute}`);
}

function findCloneLocationFromKey(data, key) {
    let location = null;
    data.forEach((entity) => {
        entity.children.forEach((item) => {
            if (item.key === key) {
                location = item;
            }
        });
    })
    return location;
}

function cloneCaptureData(flag, data, latitude, longitude) {
    const store = useContext(liveStore);
    console.log(data);
    if (data === null || data === undefined) {
        return null;
    }
    if (!data && data.length <= 0) {
        return null;
    }
    data.forEach((entity) => {
        entity.children.forEach((item) => {
            item.title = item.alias || `${item.ssid} ${macToMAC(item.mac_address)}`
            item.targetloc = {
                lat: latitude,
                lng: longitude
            }
            item.icon = <img src={`${getPublicURL()}/sniff.png`} width={'20px'} height={'20px'}></img>;
            if (item.capture_type === "WIFI_CLONE_SCAN") {
                item.icon = <img src={`${getPublicURL()}/clone.png`} width={'20px'} height={'20px'}></img>;
            }
        });
    })
    if (flag) {
        if (data && data.length > 0) {
            return (
                <div>
                    <h6>
                        Entitis in Transit
                    </h6>
                    <Tree
                        showLine={true}
                        showIcon={true}
                        onSelect={(row) => {
                            const location = findCloneLocationFromKey(data, row[0]);
                            console.log(location);
                            store.cloneCaptureMapPoint = location;
                        }}
                        treeData={data}
                        />
                </div>
            )
        } else {
            return (
                null
            )
        }
    }
    else {
        return (null);
    }
}

function convertConfigJsonToText(config) {
    let configText = "";
    if (config) {
        for (let key in config) {
            if (config[key] !== 'null') {
                configText = `  ${key} (${config[key]})`
            }
        }
    }
    return configText;
}

function speedConvertText(speed, speed_unit) {
    let speedText = "";
    if (speed_unit === "mph") {
        speed = speed * 0.277778;
    }
    if (speed > 0) {
        speedText = `Moving at ${speed} ${speed_unit}`;
    }
    else if (speed === 0) {
        speedText = `Stationary`;
    }
    else if (speed < 3) {
        speedText = `Ideal at ${speed} ${speed_unit}`;
    }
    return speedText;
}

// TODO: ccat stuff

function CCatAlarms(alarms) {
    let alarmText = "";
    if (alarms) {
        for (let key in alarms) {
            if (alarms[key] !== 'null') {
                alarmText = `  ${key} (${alarms[key]})`
            }
        }
    }
    return alarmText;
}

function DeviceDetailsIcons( {item, style, isTestLiveMap, speed_unit, list, color }) {
    const target = item;

    let batteryData = target.battery_info
    if ( typeof target.battery_info === "string" ) {
        if (batteryData.includes("&&")) {
            let battery_info = target.battery_info.split("&&");
            batteryData = battery_info[1]
        } else {
            batteryData = batteryData + "v";
        }
    }
    return (
        <div
            style={{
                ...style,
                backgroundColor: "whitesmoke",
                borderRadius: "5%",
                padding: '5%'
            }}
        >
            <div>
                <p>
                    <BatteryFull
                        style={{ paddingRight: "10px" }}
                        color={target.battery_info > 3.7 ? "green" : target.battery_info > 3.5 ? "orange" : "red"}
                        size={25}
                    />
                    {batteryData}
                </p>
                <p>
                    <GeoAltFill
                        style={{ paddingRight: "10px" }}
                        size={25}
                    />     {target.latitude || 0.0}, {target.longitude || 0.0}
                </p>
                <p>
                    <CarFrontFill
                        style={{ paddingRight: "10px" }}
                        color={target.speed > 0 ? "green" : "red"}
                        size={25}
                    />     {convertSpeed('KPH', target.speed)} / {convertSpeed('MPH', target.speed)}
                </p>
                <p>   <AddressDisplay lat={target.latitude} lng={target.longitude} />
                </p>
                <p>
                    <ClockFill
                        style={{ paddingRight: "10px" }}
                        size={25}
                    />     {target.position_lastCommsTimestamp ?  `${(new Date(Number(target.position_lastCommsTimestamp) * 1000).toLocaleString())}` : "N/A"}
                </p>
                <p>
                    <HddRackFill
                        style={{ paddingRight: "10px" }}
                        size={25}
                        color={target.lastCommsTimestamp > (Date.now() / 1000) - 60 * 60 * 24 ? "green" : "red"}
                    />     {target.lastCommsTimestamp ? `${new Date(Number(target.lastCommsTimestamp) * 1000).toLocaleString()}` : "N/A"}
                </p>
                {cloneCaptureData(list, target.cloneCapture, target.latitude, target.longitude)}
                {CCatAlarms(target.alarms)}
            </div>
        </div>
    );
}

export default observer(DeviceDetailsIcons);
