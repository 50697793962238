import PermissionStore from "../../stores/permission.store";

import { Table, Badge, Menu, Dropdown, Switch, Button, Checkbox, Tooltip, Divider, message } from "antd";
import React, {useContext} from "react";
import "./InvestigationTable.scss";
import API from "../../services/api";
import { macToMAC } from "../../util/CommonUtils";
const { Column } = Table;
import getPublicURL from "../../utilities/public-url.utility";
import TimeAgo from "react-timeago";
import { primaryButton } from "../../styles";
import EntityDisplayStore from "../../stores/entityDisplay.store";
import {observer} from "mobx-react-lite";
import wifiNetworkStore from "../../stores/wifiNetwork.store";

function AnalysisWifiNetworkTable({
    currentlyHiddenAccessPoints,
    updateList,
    sethide,
    onDelete,
    onEditRecord,
    data,
    canDelete,
    onTargetSelect,
    addDragList,
    Fave}) {
    const store = useContext(EntityDisplayStore)
    const wifiStore = useContext(wifiNetworkStore)
    function faveCheck(item) {
        if (item.metadata === null || item.metadata === undefined)  return ;
        let obj = JSON.parse(item.metadata);
        if(obj.favourited){
            return ('ant-table-row-expand-icon-expanded');
        }else {
            return ('ant-table-row-expand-icon-collapsed');
        }
    }
    const permissionStore = useContext(PermissionStore);
    const _onEditRecord = (record) => {
        onEditRecord(record);
    };

    const _onDeleteRecord = (record) => {
        onDelete(record);
    };

    const _onTargetSelect = (record) => {
        onTargetSelect(record);
    }
    const _addDragList = (record) => {
        addDragList(record)
    }
    const _entityEdit = (record) => {
        store.setDeviceAndOpen(record.id);
    }

    const entitymapping = []
    if(data && data !== null){
        for (let i = 0; i < data.length; i += 1){
            data[i].key = i;
            if(data[i].devices){
                data[i].devices.forEach(element => {
                    element.matchkey = i;
                    entitymapping.push(element);
                });
            }
        }
    }
    const expandedRowRender = (record) =>{
        let matchdata = [];
        const ap = record;
        entitymapping.forEach(element => {
            if ( element.matchkey === record.key){
                matchdata.push(element);
            }
        })
        const columns = [
            {title: "Entity", dataIndex: "entityAlias", key: "entityAlias"},
            {title: "Device (Entity)", dataIndex: "alias", key: "alias"},
            {title: "MAC", dataIndex: "mac_address", key: "mac_address", render: (text) => {
                return macToMAC(text);
            }
            },
            {title: 'Last Seen', dataIndex: 'last_seen', key: 'last_seen', render : (text, record) => {
                return (<TimeAgo date={parseInt(text, 10) * 1000 } />);
            }},
            { title: "Capture", dataIndex: "capture_type", key: "capture_type", render : (text, record) => {
                switch (text) {
                    case 'CLONE_AND_SNIFF':
                        return (
                            <div>
                                <Tooltip title="WiFI Clone Scan">
                                    <img src={`${getPublicURL()}/clone.png`} width={'20px'} height={'20px'}></img>
                                </Tooltip>
                                <Divider type="vertical" />
                                <Tooltip title="WiFi Sniff Scann">
                                    <img src={`${getPublicURL()}/sniff.png`} width={'20px'} height={'20px'}></img>
                                </Tooltip>
                            </div>
                        )
                    case 'WIFI_SNIFF_SCAN':
                        return (
                            <div>
                                <Tooltip title="WiFi Sniff Scann">
                                    <img src={`${getPublicURL()}/sniff.png`} width={'20px'} height={'20px'}></img>
                                </Tooltip>
                            </div>
                        )
                    case 'WIFI_CLONE_SCAN':
                        return (
                            <div>
                                <Tooltip title="WiFi Clone Scan">
                                    <img src={`${getPublicURL()}/clone.png`} width={'20px'} height={'20px'}></img>
                                </Tooltip>
                            </div>
                        )
                    default:
                        return null;
                }
            }
            },
        ]
        return (
            <Table
                columns={columns}
                dataSource={matchdata}
                pagination={false}
                onRow={(record) => {
                    return {
                        onDoubleClick: () => {
                            _entityEdit(record, 'device', ap);
                        }
                    }
                }}
            />
        )
    }
    const onTargetShowHideToggle = (record) => {
        sethide(record);
    }
    const PermCheck = !permissionStore.can("wifiAccessPoint.edit")

    const favouriteClick = (record) => {
        let data = JSON.parse(record.metadata);
        if(data.favourited){
            data.favourited = false;
        } else {
            data.favourited = true;
        }
        let str = JSON.stringify(data);
        API.accessPoint.updateAccessMeta(record.id, str).then((res) => {
            updateList();
        })
    }

    const columns = [
        {title: "SSID", dataIndex: "ssid", key: "ssid"},
        {title: "MAC Address", dataIndex: 'mac_address', key: 'mac_address', render: (text) => {
            return (macToMAC(text))
        }},
        {
            title: 'Hidden',
            key: 'action',
            align: 'center',
            render: (text, record) => (
                <Checkbox
                    onChange={()=>{onTargetShowHideToggle(record)}}
                />
            ),
        },
        {
            title: 'Favourite',
            key: 'action',
            align: 'center',
            render: (text, record) => {
                let classes = `ant-table-row-expand-icon ${faveCheck(record)}`;
                return (<button
                    type={'button'}
                    className={classes}
                    onClick={() => {favouriteClick(record)}}
                />)
            },
        },
        {
            title: 'Adjust',
            key: 'action',
            align: 'center',
            render: (text, record) => (
                <Switch
                    size={'small'}
                    onChange={()=>{_addDragList(record)}}
                />
            ),
        },
    ]
    //
    function visaColumns (permission = false) {
        if(permission){
            return columns
        } else {
            return columns.filter(col => col.title !== 'Adjust')
        }
    }

    return (
        <Table
            // className="components-table-nested"
            size={"small"}
            columns={visaColumns(PermCheck)}
            style= {{ tableLayout: 'fixed', width: '100%' }}
            rowKey={(record) => record.key}
            scroll={{x: 200}}
            onRow={(record) => {
                return {
                    onClick: () => {
                        if (record.latitude === 0 && record.longitude === 0 ) {
                            message.error("No location data available for this access point.");
                            return;
                        }
                        return _onTargetSelect(record);
                    },
                    onDoubleClick: () => {
                        return wifiStore.addApToSelected(record);
                    },
                    style: { tableLayout: 'fixed', cursor: 'pointer' },
                };
            }}
            expandable={{
                expandedRowRender: record => expandedRowRender(record),
                rowExpandable: record => record.mac_address,
                defaultExpandAllRows: false,
            }}
            dataSource={data}
        />
    );

}

export default observer(AnalysisWifiNetworkTable);
