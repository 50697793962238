import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import API from "../../services/api";
import Layout from "../AppLayout";
import React, { useEffect, useState, useContext } from 'react';
import WifideviceAuditTab from "../../components/WifiAuditTab"
import EntityMangementTab from "../../components/EntityManagment";
import WifiDeviceMangement from "../../components/WifiDeviceManagment";
import FuzzySearch from "fuzzy-search";
import BluetoothApi from "../../services/api/bluetooth.api";
import BluetoothDeviceManagement from "../../components/BluetoothDeviceManagement";

const { TabPane } = Tabs;

function EntityManagement() {
    const [allAnalytics, setAllAnalytics] = useState([]);
    const [allSOS, setAllSOS] = useState([]);
    const [analyticsCount, setAnalyticsCount] = useState(0);
    const [selectedEntity, setSelectedEntity] = useState(null);

    const [userAudit, setUserAudit] = useState([]);
    const [userAuditCountAll, setUserAuditCountAll] = useState(0);

    const [geospin, setGeospin] = useState(false);

    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [nickNameHistory, setNickNameHistory] = useState([]);
    const [deviceTargetHistory, setDeviceTargetHistory] = useState([]);
    const [huntsmanLiveData, setHuntsmanLiveData] = useState([]);
    const [huntsmanLastId, setHuntsmanLastId] = useState(null);
    const [
        deviceTargetHistoryCountAll,
        setDeviceTargetHistoryCountAll,
    ] = useState(0);
    const [targets, setTargets] = useState([]);
    const [selectedInvestigation, setSelectedInvestigation] = useState(null);
    const [wifiCloneHistory, setWifiClonedHistory] = useState([]);
    const [entities, setEntities] = useState([]);
    const [devices, setDevices] = useState([]);
    const [bluetooth, setBluetooth] = useState([]);
    const [ogdevices, setogdevices] = useState([]);
    const [ogBluetooth, setOgBluetooth] = useState([]);
    const [latlng, setLatlng] = useState({});
    useEffect(() => {
        searchInvestigationhandleSubmit(selectedInvestigation);
    }, [selectedInvestigation]);

    const searchInvestigationhandleSubmit = async (name) => {
        async function entity() {
            await API.entity.GetAllEntities(selectedInvestigation).then((res) => {
                setEntities(res);
            });
        }
        entity();
    }

    const InvestigationSelect = async (name) => {
        await searchInvestigationhandleSubmit(name);
        setSelectedInvestigation(name);
        async function wifi() {
            await API.wifiDevice.GeTAllDevices(name).then((res) => {
                setDevices(res);
                setogdevices(res);
            });

            // await API.
        }

        async function bluetooth() {
            await API.bluetooth.GeTAllDevices(name).then((res) => {
                setBluetooth(res);
                setOgBluetooth(res);
            })
        }
        wifi();
        bluetooth();
    }

    const clearSearch = () => {
        setDevices(ogdevices);
        setBluetooth(ogBluetooth);
    }
    // filter devices

    const filter = (words) => {
        if (words) {
            const tempdevices = [];

            while (words.includes(':')) {
                words = words.replace(":", '')
            }

            ogdevices.forEach((device) => {
                if (device.alias?.toLowerCase().includes(words.toLowerCase())) {
                    tempdevices.push(device);
                } else if (device.device?.toLowerCase().includes(words.toLowerCase())) {
                    tempdevices.push(device);
                } else if (device.accessPoint?.toLowerCase().includes(words.toLowerCase())) {
                    tempdevices.push(device);
                } else if (device.entity_alias?.toLowerCase().includes(words.toLowerCase())) {
                    tempdevices.push(device);
                }
            });

            setDevices(tempdevices);
        }
        else {
            setDevices(ogdevices);
        }
    }

    const filter2 = (words) => {
        if (words) {
            const searcher = new FuzzySearch(
                bluetooth,
                ["alias", "device", "mac_address"],
                {
                    caseSensitive: false
                }
            );
            const response = searcher.search(words.trim());
            setBluetooth(response);
        }
        else {
            setBluetooth(ogBluetooth);
        }
    }

    async function worded(entity) {
        let len = entity.AssociatedAccessPoints.length;
        for (let i = 0; i < len; i += 1) {
            const geocoder = new window.google.maps.Geocoder();
            //await new Promise(r => setTimeout(r, 1500));
            new Promise((resolve) => {
                geocoder
                    .geocode({ location: entity.AssociatedAccessPoints[i].latlng })
                    .then((response) => {
                        entity.AssociatedAccessPoints[i].worded = response.results[0].formatted_address;
                        resolve();
                    })
            })
        }
        setGeospin(false);
        return entity;
    }



    const entitySelected = async (name) => {
        let modified = {};
        await API.entity.pageEntity(name).then((res) => {
            modified = res;
        });
        await worded(modified);
        setSelectedEntity(modified);

        setLatlng({
            lat: modified.lastLocation.lat,
            lng: modified.lastLocation.lng,
        })
    }

    const onLiveMode = async (serial, from, to) => {
        setSpinnerVisible(true);
        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.analytics.getAllHuntsmanAnalytics(
            serial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            50
        );
        setHuntsmanLiveData(response.deviceAnalyticLatest);
        if (response.deviceAnalyticLatest.length > 0) {
            const huntsmanIds = response.deviceAnalyticLatest.map(x => x.id);

            setHuntsmanLastId(Math.max(...huntsmanIds))
        }
        setSpinnerVisible(false);
    }

    const timeIntervalHuntsmanAnalytics = async (serial, from, to) => {
        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.analytics.getAllHuntsmanAnalytics(
            serial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            1,
            huntsmanLastId
        );

        if (response.deviceAnalyticLatest.length > 0) {
            let huntsManDataLatest = huntsmanLiveData.filter(x => x.id === response.deviceAnalyticLatest[0].id);
            if (huntsManDataLatest.length === 0) {
                setHuntsmanLiveData([...huntsmanLiveData, ...response.deviceAnalyticLatest])
            }
        }
    }

    return (
        <div>
            <Layout
                showNavigation
                title={"Entity Management"}
                description="Manage Entitys and associated devices"
                extra={[]}
            >
                <div>
                    <EntityMangementTab
                        onFilterClicked={entitySelected}
                        analytics={nickNameHistory}
                        analyticsCount={analyticsCount}
                        targets={entities}
                        entity={selectedEntity}
                        onInvestigationSelect={(investigationID) => {
                            setSelectedInvestigation(investigationID);
                        }}
                        investigation={selectedInvestigation}
                        spinnerVisible={spinnerVisible}
                        searchInvestigationhandleSubmit={searchInvestigationhandleSubmit}
                        huntsmanData={huntsmanLiveData}
                        onLiveModeClicked={onLiveMode}
                        timeIntervalHuntsmanAnalytics={timeIntervalHuntsmanAnalytics}
                        latlng={latlng}
                        setGeospin={setGeospin}
                        geospin={geospin}
                    />
                </div>
            </Layout>
        </div>
    );
}

export default observer(EntityManagement);
